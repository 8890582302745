import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Track active icon by index

  const handleClick = (index) => {
    setActiveIndex(index); // Set the clicked icon as active
  };

  const icons = [
    { to: "/", src: "images/search.png", activeSrc: "images/search-active.png", label: "Search" },
    { to: "/agents", src: "images/agent.png", activeSrc: "images/agent-active.png", label: "Agent" },
    { to: "/lawyers", src: "images/lawyer.png", activeSrc: "images/lawyer-active.png", label: "Lawyer" },
    { to: "/loans", src: "images/loan.png", activeSrc: "images/loan-active.png", label: "Loan" },
    { to: "/Favourite", src: "images/heart.png", activeSrc: "images/heart-active.png", label: "Heart" },
    { to: "/profile", src: "images/user.png", activeSrc: "images/user-active.png", label: "User" },
  ];

  return (
    <div className="dev-stickyfooter">
      <div className="footer-items">
        {icons.map((icon, index) => (
          <NavLink
            key={index}
            to={icon.to}
            className="item no-underline"
            activeClassName="active"
          >
            <div
              className="icon_active"
              onClick={() => handleClick(index)}
              style={{ cursor: "pointer" }}
            >
              <img
                src={activeIndex === index ? icon.activeSrc : icon.src}
                alt={icon.label}
                style={{ width: "75%", height: "30px" }}
              />
              <p
                style={{
                  margin: 0,
                  color: activeIndex === index ? "red" : "black",
                }}
              >
                {icon.label}
              </p>
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Footer;
