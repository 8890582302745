import React from "react";
import { FiUser, FiSettings } from "react-icons/fi"; // Feather Icons
import { FaSignInAlt } from "react-icons/fa"; // Font Awesome
import { Link } from "react-router-dom";
import { MdAddBusiness } from "react-icons/md";
import { MdOutlineAddBusiness } from "react-icons/md";
import { FaUserCog } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { BsQuestionCircle } from "react-icons/bs";

const UserAcount = () => {
  return (
    <main>
      <div className="user-profile customPageWIdth section-margin">
        <div className="before-login login-width">
          <div className="iconbtn-wrapper">
            {/* Profile Icon */}
            <div className="profile-icon">
              <FiUser size={24} color="#292D32" />
            </div>
            <button className="to-login">Login to your account!</button>
          </div>

          <div className="addproperty-wrapper">
            <div className="containermenuitem">
              <div className="menu-item">
                <FaUserCog
                  style={{ width: "25px", height: "25px", color: "#333" }}
                />
                <span>Manage Profile</span>
              </div>
              <div className="menu-item">
                <FaBuilding
                  style={{ width: "25px", height: "25px", color: "#333" }}
                />
                <span>Properties</span>
              </div>
              <div className="menu-item">
                <MdAddBusiness
                  style={{ width: "25px", height: "25px", color: "#333" }}
                />
                <div className="addproperty-text">
                  <Link to="/AddProperty" style={{ textDecoration: "none" }}>
                    <span style={{ display: "flex", color: "black" }}>
                      Add Property
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="settinglogin-wrapper">
            {/* Settings Icon */}
            <div className="settings child">
              <div className="icon">
                <FiSettings size={24} color="#292D32" />
              </div>
              <span>Settings</span>
            </div>
            <div className="menu-items">
              <div className="icon">
                <BsQuestionCircle
                  style={{ width: "25px", height: "25px", color: "#333" }}
                />
              </div>
              <span>Request Demo</span>
            </div>
            {/* Login Icon */}
            <div className="login child">
              <Link to="/login">
                <div className="icon">
                  <FaSignInAlt size={24} color="#292D32" />
                </div>
              </Link>
              <Link
                to="/login"
                style={{ textDecoration: "none", color: "#333" }}
              >
                <span>Login</span>
              </Link>
            </div>
          </div>

          {/* Footer Text */}
          <div className="yidV-text">
            <p>YidEstate</p>
            <p>Version 0.9.1 beta</p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UserAcount;
