import React, { useState, useRef } from "react";
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import { FaSearch } from "react-icons/fa";
import { Button } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";

const Map = () => {
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
   location:'',
    address: '',
    latitude: '',
    longitude: '',
    country: '',
    state: '',
    city: '',
    area: '',
    postal_code: ''
  });
  const autocompleteRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [markers, setMarkers] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [success, setSuccess] = useState(null);
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlevalueSearch = (event) => {
    setSearchTerm(event.target.value);
  };

 
  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();

    if (place && place.geometry && place.geometry.location) {
      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      setFormData({ ...formData, location: place.formatted_address });
      setMarkers([location]);
    } else {
      console.error("Place or geometry is undefined.");
    }
  };

  const mapStyles = {
    height: '100vh',
    width: '100%',
  };
  const countries = [
    { value: 'Afghanistan', label: 'Afghanistan' },
    { value: 'Åland Islands', label: 'Åland Islands' },
    { value: 'Albania', label: 'Albania' },
    { value: 'Zimbabwe', label: 'Zimbabwe' },
    { value: 'Pakistan', label: 'Pakistan' },
    { value: 'India', label: 'India' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Amrican', label: 'Amrican' },
    { value: 'China', label: 'China' },
    { value: 'Span', label: 'Span' },
  ];
  const states = [
    { value: 'Alabama', label: 'Alabama' },
    { value: 'Alaska', label: 'Alaska' },
    { value: 'Arizona', label: 'Arizona' },
    { value: 'Wyoming', label: 'Wyoming' },
  ];

  const cities = [
    { value: 'kasur', label: 'Kasur' },
    { value: 'Lahore', label: 'Lahore' },
    { value: 'Islambed', label: 'Islambed' },
    { value: 'Multan', label: 'Multan' },
  ];
  const areas = [
    { value: 'Alabama', label: 'Alabama' },
    { value: 'Alaska', label: 'Alaska' },
    { value: 'Arizona', label: 'Arizona' },
    { value: 'Wyoming', label: 'Wyoming' },
  ];

 
  //formdate
  const handleLatitudeChange = (event) => {
    setFormData({ ...formData, latitude: event.target.value });
  };
  
  const handleLongitudeChange = (event) => {
    setFormData({ ...formData, longitude: event.target.value });
  };
  
  const handleCountryChange = (event) => {
    setFormData({ ...formData, country: event.target.value });
  };
  
  const handleStateChange = (event) => {
    setFormData({ ...formData, state: event.target.value });
  };
  
  const handleCityChange = (event) => {
    setFormData({ ...formData, city: event.target.value });
  };
  
  const handleAreaChange = (event) => {
    setFormData({ ...formData, area: event.target.value });
  };
  
  const handlePostalCodeChange = (event) => {
    setFormData({ ...formData, postal_code: event.target.value });
  };
  const handleAddressChange = (event) => {
    setFormData({ ...formData, address: event.target.value });
  };
  //button
  const handleClick = () => {
    console.log('Button clicked!');
  };
  const libraries = ['places'];
  const validateForm = () => {
    const errors = {};
 
    if (!formData.latitude) {
      errors.latitude = "latitude is required";
    }
    if (!formData.longitude) {
      errors.longitude = "longitude is required";
    }
    if (!formData.address) {
      errors.address = "address is required";
    }
   
    // Add additional validations as needed

    return errors;
  };
  
const handleNext = async (event) => {
  event.preventDefault();

  const errors = validateForm();
  if (Object.keys(errors).length > 0) {
    setFormErrors(errors);
    return;
  }
  const formDataObj = new FormData();
  formDataObj.append('location', formData.location);
  formDataObj.append('address', formData.address);
  formDataObj.append('latitude', formData.latitude);
  formDataObj.append('longitude', formData.longitude);
  formDataObj.append('country', formData.country);
  formDataObj.append('state', formData.state);
  formDataObj.append('city', formData.city);
  formDataObj.append('area', formData.area);
  formDataObj.append('postal_code', formData.postal_code);
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      property_id: localStorage.getItem("properties"),
      location: formData.location || '',
      address: formData.address || '',
      latitude: formData.latitude || '',
      longitude: formData.longitude || '',
      country: formData.country || '',
      state: formData.state || '',
      city: formData.city || '',
      area: formData.area || '',
      postal_code: formData.postal_code || '',
      user_id: JSON.parse(localStorage.getItem("user"))._id
    }),
    headers: {
      "Content-Type": "application/json",
      "Authorization": 'Bearer ' + localStorage.getItem("bearer-token")
    }
  };
const response = await fetch(
  `${process.env.REACT_APP_backend_server_url}/addresses`,
  requestOptions
);
  // const response = await fetch('https://763c-139-135-43-100.ngrok-free.app/addresses', requestOptions);

  if (response.ok) {
    const result = await response.json();
  
    console.log('Property added successfully', result.message);
    setSuccess(result.message);

navigate("/floorplan");
    // Reset form fields
    setFormData({
      location:'',
      address: '',
      latitude: '',
      longitude: '',
      country: '',
      state: '',
      city: '',
      area: '',
      postal_code: ''
    });
  } else {
    const result = await response.text();
    console.error('Error adding property', result);
    setSuccess(null);
  }

};

  return (
    <form>
      <div className="add-property">
        <div className="form-wrapper" style={{ boxShadow: '0px 1px 3px -1px', marginTop: '12px', borderRadius: '9px' }}>
          <span className="form-span">Features</span>
          <div className="form-controll">
          <div style={{ position: 'relative', justifyContent:'center', display:'flex', border:'black' }}>
  <input
  className='form-term'
    type="text"
    value={searchTerm}
    onChange={handlevalueSearch}
    placeholder="Search"
    style={{ padding: '10px', paddingLeft: '30px' }}
  />
    <FaSearch style={{ position: 'absolute', right: '50px', top: '22px', zIndex: '1' }} />

</div>
<LoadScript
      googleMapsApiKey="AIzaSyBUSG5V2J28-4MJ5is2lWgC4nrd6m3XaaQ"
      libraries={["places"]}
    >
      <Autocomplete
        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
        onPlaceChanged={handlePlaceChanged}
        options={{
          types: ["geocode"], // or 'address' / 'establishment' based on your needs
          componentRestrictions: { country: "pk" },
        }}
      >
        <input
          className="locat"
          type="text"
          value={formData.location}
          onChange={(event) =>
            setFormData({ ...formData, location: event.target.value })
          }
          placeholder="Search for a location"
        />
      </Autocomplete>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={9}
        center={{ lat: 37.7749, lng: -122.4194 }}
      >
        {markers.map((marker, index) => (
          <Marker key={index} position={marker} />
        ))}
      </GoogleMap>
    </LoadScript>
          </div>
          <div  className="form-control">
      <label className='form-label'>Latitude:</label>
      <input
        className='form-input' 
        type="text"
    value={formData.latitude}
    onChange={handleLatitudeChange}
    placeholder="Enter the Latitude"
  />
   {formErrors.latitude && (
              <span className="error-app" >{formErrors.latitude}</span>
            )}
      <br />
      <label className='form-label'>Longitude:</label>
      <input
      className='form-input' 
        type="number"
        value={formData.longitude}
    onChange={handleLongitudeChange}
    placeholder="Enter the Longitude"
  />
   {formErrors.longitude && (
              <span className="error-app" >{formErrors.longitude}</span>
            )}
    </div>
        </div>
        <div className="form-wrapper" style={{ boxShadow: '0px 1px 3px -1px', marginTop: '12px', borderRadius: '9px' }}>
        <span className="form-span">Location</span>
        <div  className="form-control">
      <label className='form-label'>Address:</label>
      <input
        className='form-input' 
        type="text"
        value={formData.address}
        onChange={handleAddressChange}
        placeholder=" Enter the Address "
      />
       {formErrors.address && (
              <span className="error-app" >{formErrors.address}</span>
            )}
      </div>
      <div className="form-control" >
      <label className='form-label'>Country:</label>
      <select
          className='form-input' 
    value={formData.country}
    onChange={handleCountryChange}
  >
    <option value="">Select a country</option>
    {countries.map((country) => (
      <option key={country.value} value={country.value}>
        {country.label}
      </option>
    ))}
  </select>
    </div>
    <div className="form-control" >
      <label className='form-label'>State:</label>
      <select
       className='form-input' 
    value={formData.state}
    onChange={handleStateChange}
  >
    <option value="">Select a state</option>
    {states.map((state) => (
      <option key={state.value} value={state.value}>
        {state.label}
      </option>
    ))}
  </select>
    </div>
    <div className="form-control" >
      <label className='form-label'>City:</label>
      <select
       className='form-input' 
    value={formData.city}
    onChange={handleCityChange}
  >
    <option value="">Select a city</option>
    {cities.map((city) => (
      <option key={city.value} value={city.value}>
        {city.label}
      </option>
    ))}
  </select>
    </div>
    <div className="form-control" >
      <label className='form-label'>Area:</label>
      <select
       className='form-input' 
    value={formData.area}
    onChange={handleAreaChange}
  >
    <option value="">Select an area</option>
    {areas.map((area) => (
      <option key={area.value} value={area.value}>
        {area.label}
      </option>
    ))}
  </select>
      
    </div>
    <div  className="form-control">
      <label className='form-label'>postal_code:</label>
      <input
        className='form-input' 
        type="text"
        value={formData.postal_code}
        onChange={handlePostalCodeChange}
        placeholder="Enter the Postal code"
      />
      </div>

</div>
<div className="form-wrapper" style={{boxShadow:'0px 1px 3px -1px',marginTop:'12px', borderRadius:'9px'}}>
      <div style={{display: 'flex',
    justifyContent: 'space-between'}}>
<div  className="form-containe">
      <Button
      className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleClick}
      >
        Cancel
      </Button>
    </div>
    <div className='form-contain'>
      <Button
       className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleNext}
      >
       Next
      </Button>
    </div>
</div>

      </div>
      </div>
    </form>
  );
};

export default Map;