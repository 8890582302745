

import React, { useState, useEffect } from "react";
import { FaUserLarge } from "react-icons/fa6";
import { Link } from "react-router-dom";

const LoanPage = () => {
  const [loans, setLoans] = useState([]); // State to store loan data
  const [showSearchForm, setShowSearchForm] = useState(false); // Toggle for search form
  const [searchQuery, setSearchQuery] = useState(""); // Search query

  // Toggle search form visibility
  const handleLocationClick = () => {
    setShowSearchForm((prevState) => !prevState);
  };

  // Fetch loan data from API
  useEffect(() => {
    const fetchLoans = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("bearer-token"),
        },
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_backend_server_url}/users/roleLoans`,
          requestOptions
        );

        if (response.ok) {
          const result = await response.json();
          setLoans(result); // Set loan data
          console.log("Loan data:", result);
        } else {
          console.error("Loan data not found");
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchLoans();
  }, []);

  // Filter Loans by search query
  const filteredLoans = loans.filter((loan) =>
    loan.userprofiles[0]?.display_name
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <div className="agentsPage customPageWidth section-margin">
      <div className="container-agent">
        <h1 className="page-title">Loans</h1>
      </div>

      {/* Search Bar */}
      <div className="search-container" onClick={handleLocationClick}>
        <input
          type="text"
          placeholder="🔍 Type Agent's Name Here....."
          className="search-input"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {showSearchForm && (
        <div className="search-form-agent">
          <h3>Find Agent</h3>
          <input
            type="text"
            className="search-bar-agent"
            placeholder="Type agent name here"
            autoFocus
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button className="search-button-agent">
            <span>Search Agent</span>
          </button>
        </div>
      )}

      {/* Agents List */}
      <div className="block-container">
        {filteredLoans.map((loan, index) => (
          <div className="block" key={index}>
            <h1 className="word">{loan.userprofiles[0]?.first_name?.[0] || "A"}</h1>
            <div className="end" id="loanName">
              <div className="section-page">
                <img
                  alt={`Image of ${loan.userprofiles[0]?.first_name || "loan"}`}
                  height="40"
                  src={loan.addimages[0]?.profile_image || "https://via.placeholder.com/40"}
                  width="40"
                />
                <div className="text">
                  <div className="addproperty-text">
                    <Link
                      to={`/ProfilePage/${loan.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <span style={{ display: "flex", color: "black" }}>
                        {loan.userprofiles[0]?.first_name || "loan Name"}
                      </span>
                    </Link>
                  </div>             
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoanPage;
