import React, { useState, useEffect } from "react";
import { FaRulerCombined, FaMapMarkerAlt, FaBed, FaBath } from "react-icons/fa";
import Flickity from "react-flickity-component";

const SearchByAgent = () => {
    const flickityOptions = {
        initialIndex: 0,
        wrapAround: true,
        pageDots: true,
        prevNextButtons: true,
        cellAlign: 'left',
        contain: true,
    };

    const [random, setRandom] = useState([]);

    const fetchProperties = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                'ngrok-skip-browser-warning': 'any',
                authorization: "Bearer " + localStorage.getItem("bearer-token"),
            },
        };

        const response = await fetch(
            `${process.env.REACT_APP_backend_server_url}/properties/random`,
            requestOptions
        );

        if (response.ok) {
            const result = await response.json();
            if (result.properties) {
                setRandom(result.properties);
                console.log("Properties fetched successfully", result.properties);
            }
        } else {
            const result = await response.json(); // Parse JSON response for errors
            console.error("Error fetching properties", result);
        }
    };

    useEffect(() => {
        fetchProperties();
    }, []);

    return (
        <>
            <div className="cards main-carousel">
                <Flickity
                    className={"property-items carousel"}
                    elementType={"div"}
                    options={flickityOptions}
                    reloadOnUpdate={true}
                >
                    {random.length > 0 ? random.map((property, id) => (
                        <div className="card" data-index={id} key={property.id}>
                            <div className="card-mainImage">
                                <img
                                    src={
                                        property.uploads.length > 0
                                            ? property.uploads[0].media
                                            : "https://via.placeholder.com/400x200"
                                    }
                                    alt={property.property_title}
                                />
                            </div>
                            <div className="card-content">
                                <p className="card-heading">{property.property_title}</p>
                                {/* You can add more property details here */}
                            </div>
                        </div>
                    )) : <p>No properties available.</p>}
                </Flickity>
            </div>
        </>
    );
};

export default SearchByAgent;