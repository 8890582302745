import React, { useState } from 'react';

const Schedule = () => {
    const [gdprAgreement, setGdprAgreement] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [formData, setFormData] = useState({
        tour_type: '',
        Schedule_time: '',  
        Schedule_date: '',
        message: '',
      });
    const [formErrors, setFormErrors] = useState({
        message: '',
    });
    
    const handleChange = (event) => {
        if (event.target.type === "file") {
            setFormData({ ...formData, [event.target.name]: event.target.files[0] });
        } else {
            setFormData({ ...formData, [event.target.name]: event.target.value });
        }
    };

    const handleGdprAgreementChange = (event) => {
        setGdprAgreement(event.target.checked);
    };
    const validatePhoneNumber = (number) => {
        // Example regex for a 10-digit phone number
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(number);
    };
    // Handle form submission
    const handleNext = async (event) => {
        event.preventDefault();
    
        // Check for required fields before sending
        if (!formData.tour_type || !formData.Schedule_time || !formData.Schedule_date || !phone) {
            setError("Please fill out all required fields.");
            return;
        }
    
        // Validate phone number
        if (!validatePhoneNumber(phone)) {
            setError("Please enter a valid phone number (10 digits).");
            return;
        }
      
        const requestBody = {
            property_id: localStorage.properties,
            tour_type:  formData.tour_type, 
            schedule_time: formData.Schedule_time,
            schedule_date: formData.Schedule_date,
            name: name,
            email: email,
            phone: phone,
            message: formData.message,
            user_id: JSON.parse(localStorage.user)._id,
        };
    
        console.log("Form data submitted:", requestBody);
    
        const requestOptions = {
            method: "POST",
            body: JSON.stringify(requestBody),
            headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + localStorage.getItem("bearer-token"),
            },
        };
    
        const response = await fetch(
            `${process.env.REACT_APP_backend_server_url}/schedule`,
            requestOptions
        );
    
        if (response.ok) {
            const result = await response.json();
            console.log("Schedule added successfully", result.message);
            setSuccess(result.message);
            
            // Reset form fields
            setFormData({
                tour_type: '',
                Schedule_time: '',
                Schedule_date: '',
                message: '',
            });
            setName('');
            setEmail('');
            setPhone('');
            setGdprAgreement(false);
        } else {
            const result = await response.json(); // Parse JSON response for errors
            console.error("Error adding schedule", result);
            setError(result.message); // Display the error message
        }
    };

  return (
      <form>
      <div className="add-property">
        <div className="form-wrapper" style={{ boxShadow: "0px 1px 3px -1px" }}>
        <span className='form-span'>Tour Information</span>
        <div className="form-control">
            <label className="form-label">Tour Type</label>
            <select
    className="form-input"
    name="tour_type"
    required
    value={formData.tour_type}
    onChange={handleChange}
>
    <option value="">Select Type</option>
    <option value="in-person">In Person</option>
    <option value="video-chat">Video Chat</option>
</select>
           
          </div>
          <div className="form-control">
  <label className="form-label">Time</label>
  <input
    className="form-input"
    type="time"
    name="Schedule_time"  // Use the same name as the state
    required
    placeholder="Enter your the time"
    value={formData.Schedule_time}  // This binds to the correct state field
    onChange={handleChange}
  />
</div>

<div className="form-control">
  <label className="form-label">Date</label>
  <input
    className="form-input"
    type="date"
    name="Schedule_date"  // Use the same name as the state
    required
    value={formData.Schedule_date}  // This binds to the correct state field
    onChange={handleChange}
  />
</div>
            </div>
            <div className="form-wrapper" style={{ boxShadow: "0px 1px 3px -1px" }}>
          <span className="form-span">Your Information</span>
          
          <div className="form-control">
            <label className="form-label" htmlFor="name">Name</label>
            <input
              className="form-input"
              id="name"
              type="text"
              placeholder="Enter Your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          
          <div className="form-control">
            <label className="form-label" htmlFor="email">Email</label>
            <input
              className="form-input"
              id="email"
              type="text"
              placeholder="Enter Your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-control">
            <label className="form-label" htmlFor="phone">Phone</label>
            <input
    className="form-input"
    id="phone"
    type="tel" // Change this to "tel"
    placeholder="Enter Your Phone number"
    value={phone}
    onChange={(e) => setPhone(e.target.value)}
/>
          </div>

          <div className="form-control">
            <label className="form-label">Message:</label>
            <textarea
              className="form-input"
              style={{ display: "flex", height: "165px" }}
              required
              name="message"
              placeholder="Enter Your Message"
              value={formData.message}
              onChange={handleChange}
            />
            {formErrors.message && (
              <span className="error">{formErrors.message}</span>
            )}
          </div>

          <div className="form-controller" style={{ marginLeft: "45px", marginTop: "12px", display: "flex", gap: "25px", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={gdprAgreement}
              onChange={handleGdprAgreementChange}
              id="gdpr-agreement"
            />
            <label htmlFor="gdpr-agreement">
              By submitting this form, I agree to the <a href="#" target="_blank">Terms and Conditions</a>
            </label>
          </div>

          <div className="form-control">
            <button type="button" className="form-submit" onClick={handleNext}>Submit</button>
          </div>

          {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
          {success && <p style={{ color: "green", textAlign: "center" }}>{success}</p>}
        </div>
            </div>
            </form>
  )
}

export default Schedule