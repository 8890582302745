import React, { useState, useEffect } from "react";
import { FaUserLarge } from "react-icons/fa6";
import { Link } from "react-router-dom";

const LawyerPage = () => {
  const [lawyers, setLawyers] = useState([]); // State to store lawyer data
  const [showSearchForm, setShowSearchForm] = useState(false); // Toggle for search form
  const [searchQuery, setSearchQuery] = useState(""); // Search query
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(""); // Error state

  // Toggle search form visibility
  const handleLocationClick = () => {
    setShowSearchForm((prevState) => !prevState);
  };

  // Fetch lawyer data from API
  useEffect(() => {
    const fetchLawyers = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("bearer-token"),
        },
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_backend_server_url}/users/roleLawyer`,
          requestOptions
        );

        if (response.ok) {
          const result = await response.json();
          setLawyers(result); // Set lawyer data
          console.log("Lawyer data:", result);
        } else {
          setError("Lawyer data not found");
        }
      } catch (error) {
        setError("An error occurred while fetching lawyer data.");
        console.error("Fetch error:", error);
      } finally {
        setLoading(false); // Set loading to false after fetch
      }
    };

    fetchLawyers();
  }, []);

  // Filter lawyers by search query
  const filteredLawyers = lawyers.filter((lawyer) =>
    lawyer.userprofiles[0]?.display_name
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase())
  );


  return (
    <div className="agentsPage customPageWidth section-margin">
      <div className="container-agent">
        <h1 className="page-title">Lawyers</h1>
      </div>

      <div className="search-container" onClick={handleLocationClick}>
        <div>
          <input
            type="text"
            placeholder="🔍Type Lawyers Name Here....."
            className="search-input"
          />
        </div>
      
      </div>
      {showSearchForm && (
        <div className="search-form-agent">
          <h3>Find Agent</h3>
          <input
            type="text"
            className="search-bar-agent"
            placeholder="Type agent name here"
            autoFocus
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button className="search-button-agent">
            <span>Search Agent</span>
          </button>
        </div>
      )}

      <div className="agents-main-dev"></div>

      <div className="block-container">
        {filteredLawyers.map((lawyer, index) => (
          <div className="block" key={index}>
            <h1 className="word">{lawyer.userprofiles[0]?.first_name?.[0] || "A"}</h1>
            <div className="end" id="lawyerName">
              <div className="section-page">
                <img
                  alt={`Image of ${lawyer.userprofiles[0]?.first_name || "lawyer"}`}
                  height="40"
                  src={lawyer.addimages[0]?.profile_image || "https://via.placeholder.com/40"}
                  width="40"
                />
                <div className="text">
                  <div className="addproperty-text">
                    <Link
                      to={`/ProfilePage/${lawyer.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <span style={{ display: "flex", color: "black" }}>
                        {lawyer.userprofiles[0]?.first_name || "lawyer Name"}
                      </span>
                    </Link>
                  </div>             
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LawyerPage