import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

const Feature = () => {
  const [checkboxes, setCheckboxes] = useState({
    Air_Conditioning: false,
    Gym: false,
    Laundry: false,
    Lawn: false,
    Microwave: false,
    Outdoor_Shower: false,
    Refrigerator: false,
    Sauna: false,
    Swimming_Pool: false,
    TV_Cable: false,
    Washer: false,
    WiFi: false,
    Window_Coverings: false
  });
  const handleClick = () => {
    console.log('Button clicked!');
  };
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const handleChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes((prevCheckboxes) => ({ ...prevCheckboxes, [name]: checked }));
    setFormData({ ...formData, [name]: checked });
  };

  const handleNext = async (event) => {
    event.preventDefault();
    const formDataObj = {
      property_id: localStorage.getItem("properties"),
      air_conditioning: formData.Air_Conditioning || '',
      gym: formData.Gym || false,
      laundry: formData.Laundry || false,
      lawn: formData.Lawn || false,
      microwave: formData.Microwave || false,
      outdoor_shower: formData.Outdoor_Shower || false,
      refrigerator: formData.Refrigerator || false,
      sauna: formData.Sauna || false,
      swimming_pool: formData.Swimming_Pool || false,
      tv_cable: formData.Tv_Cable || false,
      washer: formData.Washer || false,
      wifi: formData.Wifi || false,
      window_coverings: formData.Window_Coverings || false,
      user_id: JSON.parse(localStorage.getItem("user"))._id
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(formDataObj),
      headers: {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + localStorage.getItem("bearer-token")
      }
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_backend_server_url}/features`,
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        console.log('Property added successfully', result.message);
        navigate("/map");
      } else {
        const errorText = await response.text();
        console.error('Error adding property:', errorText);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  return (
    <from>
            <div className='add-property'>
            <div className="form-wrapper" style={{boxShadow:'0px 1px 3px -1px',marginTop:'12px', borderRadius:'9px'}}>
            <span className='form-span'>Features</span>
            
            <div className="form-checkbox">
        <span>Air Conditioning</span>
        <input
          className="form-target"
          type="checkbox"
          id="Air_Conditioning"
          name="Air_Conditioning"
          value={formData.air_conditioning}
          checked={checkboxes.Air_Conditioning}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Gym</span>
        <input
          className="form-target"
          type="checkbox"
          id="Gym"
          name="Gym"
          value={formData.gym}
          checked={checkboxes.Gym}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Laundry</span>
        <input
          className="form-target"
          type="checkbox"
          id="Laundry"
          name="Laundry"
          value={formData.laundry}
          checked={checkboxes.Laundry}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Lawn</span>
        <input
          className="form-target"
          type="checkbox"
          id="Lawn"
          name="Lawn"
          value={formData.lawn}
          checked={checkboxes.Lawn}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Microwave</span>
        <input
          className="form-target"
          type="checkbox"
          id="Microwave"
          name="Microwave"
          value={formData.microwave}
          checked={checkboxes.Microwave}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Outdoor Shower</span>
        <input
          className="form-target"
          type="checkbox"
          id="Outdoor Shower"
          name="Outdoor_Shower"
          value={formData.outdoor_shower}
          checked={checkboxes.Outdoor_Shower}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Refrigarter </span>
        <input
          className="form-target"
          type="checkbox"
          id="Refrigarter"
          name="Refrigarter"
          value={formData.refrigarter}
          checked={checkboxes.Refrigarter}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Sauna</span>
        <input
          className="form-target"
          type="checkbox"
          id="Sauna"
          name="Sauna"
          value={formData.sauna}
          checked={checkboxes.Sauna}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Swiming Pool</span>
        <input
          className="form-target"
          type="checkbox"
          id="Swiming_Pool"
          name="Swiming_Pool"
          value={formData.swiming_pool}
          checked={checkboxes.Swiming_Pool}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>TV Cable</span>
        <input
          className="form-target"
          type="checkbox"
          id="TV_Cable"
          name="TV_Cable"
          value={formData.tv_cable}
          checked={checkboxes.TV_Cable}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>washer</span>
        <input
          className="form-target"
          type="checkbox"
          id="washer"
          name="washer"
          value={formData.washer}
          checked={checkboxes.washer}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>WiFi</span>
        <input
          className="form-target"
          type="checkbox"
          id="WiFi"
          name="WiFi"
          value={formData.wifi}
          checked={checkboxes.WiFi}
          onChange={handleChange}
        />
      </div>
      <div className="form-checkbox">
        <span>Window Cverings</span>
        <input
          className="form-target"
          type="checkbox"
          id="Window_Cverings"
          name="Window_Cverings"
          value={formData.window_cverings}
          checked={checkboxes.Window_Cverings}
          onChange={handleChange}
        />
      </div>
</div>
<div className="form-wrapper" style={{boxShadow:'0px 1px 3px -1px',marginTop:'12px', borderRadius:'9px'}}>
      <div style={{display: 'flex',
    justifyContent: 'space-between'}}>
<div  className="form-containe">
      <Button
      className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleClick}
      >
        Cancel
      </Button>
    </div>
    <div className='form-contain'>
      <Button
       className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleNext}
      >
       Next
      </Button>
    </div>
</div>

      </div>
    </div>
   
    </from>
  )
}

export default Feature