import React, { useState } from "react";
import { Button } from '@mui/material';

const EditProfile = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [success, setSuccess] = useState("");
  const fetchProfile = async () => {
    const userId = JSON.parse(localStorage.getItem("user"))._id;
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("bearer-token"),
        },
    };
    try {
    
        const response = await fetch(
            `${process.env.REACT_APP_backend_server_url}/userprofile/user/${userId}`,
            requestOptions
        );
      
   
        if (response.ok && response.status == 200) {
          const result = await response.json();
        
          setFormData({
            first_name: result.first_name,
            last_name: result.last_name,
            display_name: result.display_name,
            position: result.position,
            license: result.license,
            mobile_number: result.mobile_number,
            whatsapp_number: result.whatsapp_number,
            line_id: result.line_id,
            telegram: result.telegram,
            text_number: result.text_number,
            phone_number: result.phone_number,
            fax_number: result.fax_number,
            language: result.language,
            company_name: result.company_name,
            address: result.address,
            service_areas: result.service_areas,
            specialties: result.specialties,
            about_me: result.about_me,
            facebook: result.facebook,
            twitter: result.twitter,
            linkedin: result.linkedin,
            instagram: result.instagram,
            youtube: result.youtube,
            pinterest: result.pinterest,
            vimeo: result.vimeo,
          });
          setUsername('');
          setEmail('');
          console.log("Profile data:", result);
        }
        
        else{
            console.log(
              "Profile Not Found"
            )
        }
    } catch (error) {
    
        console.error("Fetch error:", error);
    }
};

fetchProfile();
  const currentUser = JSON.parse(localStorage.user);
  const [formData, setFormData] = useState({
    first_name: currentUser.profile?.first_name || "",
    last_name: currentUser.profile?.last_name || "",
    display_name: currentUser.profile?.display_name || "",
    position: currentUser.profile?.position ||  "",
    license: currentUser.profile?.license ||  "",
    mobile_number: currentUser.profile?.mobile_number ||  "",
    whatsapp_number: currentUser.profile?.whatsapp_number ||  "",
    line_id:currentUser.profile?.line_id ||  "",
    telegram: currentUser.profile?.telegram || "",
    text_number: currentUser.profile?.text_number ||  "",
    phone_number:currentUser.profile?.phone_number ||   "",
    fax_number: currentUser.profile?.fax_number || "",
    language: currentUser.profile?.language ||  "",
    company_name: currentUser.profile?.company_name ||  "",
    address: currentUser.profile?.address ||  "",
    service_areas:currentUser.profile?.service_areas||  "",
    specialties: currentUser.profile?.specialties ||  "",
    about_me:currentUser.profile?.about_me ||  "",
    facebook:currentUser.profile?.facebook ||   "",
    twitter: currentUser.profile?.twitter ||  "",
    linkedin: currentUser.profile?.linkedin ||  "",
    instagram:currentUser.profile?.instagram ||  "",
    youtube:currentUser.profile?.youtube ||  "",
    pinterest:currentUser.profile?.pinterest ||  "",
    vimeo: currentUser.profile?.vimeo ||  "",
  });

  const handleChange = (event) => {
    if (event.target.type === "file") {
        setFormData({ ...formData, [event.target.name]: event.target.files[0] });
    } else {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    }
};

  const handleNext = async (event) => {
    event.preventDefault();

  
    const formDataObj = new FormData();
    formDataObj.append('first_name', formData.first_name || '');
    formDataObj.append('last_name', formData.last_name || '');
    formDataObj.append('display_name', formData.display_name || '');
    formDataObj.append('position', formData.position || '');
    formDataObj.append('license', formData.license || '');
    formDataObj.append('mobile_number', formData.mobile_number || '');
    formDataObj.append('whatsapp_number', formData.whatsapp_number || '');
    formDataObj.append('line_id', formData.line_id || '');
    formDataObj.append('telegram', formData.telegram || '');
    formDataObj.append('text_number', formData.text_number || '');
    formDataObj.append('phone_number', formData.phone_number || '');
    formDataObj.append('fax_number', formData.fax_number || '');
    formDataObj.append('language', formData.language || '');
    formDataObj.append('company_name', formData.company_name || '');
    formDataObj.append('address', formData.address || '');
    formDataObj.append('service_areas', formData.service_areas || '');
    formDataObj.append('specialties', formData.specialties || '');
    formDataObj.append('about_me', formData.about_me || '');
    formDataObj.append('facebook', formData.facebook || '');
    formDataObj.append('twitter', formData.twitter || '');
    formDataObj.append('linkedin', formData.linkedin || '');
    formDataObj.append('instagram', formData.instagram || '');
    formDataObj.append('youtube', formData.youtube || '');
    formDataObj.append('pinterest', formData.pinterest || '');
    formDataObj.append('vimeo', formData.vimeo || '');
    formDataObj.append("username", username);
    formDataObj.append("email", email);
 
  
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        
        // rating_id: localStorage.rating,
        first_name: formData.first_name || '',
        last_name: formData.last_name || '',
        display_name: formData.display_name || '',
        position: formData.position || '',
        license: formData.license || '',
        mobile_number: formData.mobile_number || '',
        whatsapp_number: formData.whatsapp_number || '',
        line_id: formData.line_id || '',
        telegram: formData.telegram || '',
        text_number: formData.text_number || '',
        phone_number: formData.phone_number || '',
        fax_number: formData.fax_number || '',
        language: formData.language || '',
        company_name: formData.company_name || '',
        address: formData.address || '',
        service_areas: formData.service_areas || '',
        specialties: formData.specialties || '',
        about_me: formData.about_me || '',
        facebook: formData.facebook || '',
        twitter: formData.twitter || '',
        linkedin: formData.linkedin || '',
        instagram: formData.instagram || '',
        youtube: formData.youtube || '',
        pinterest: formData.pinterest || '',
        vimeo: formData.vimeo || '',
        username: username,
        email: email,
        user_id: JSON.parse(localStorage.user)._id
      }),
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("bearer-token"),
      },
    };

    try {
      debugger;
      const response = await fetch(
        `${process.env.REACT_APP_backend_server_url}/userprofile`, 
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        debugger;
        console.log("Profile updated successfully", result.message);
        setSuccess(result.message);

        // Reset form fields
        setFormData({
          first_name: "",
          last_name: "",
          display_name: "",
          position: "",
          license: "",
          mobile_number: "",
          whatsapp_number: "",
          line_id: "",
          telegram: "",
          text_number: "",
          phone_number: "",
          fax_number: "",
          language: "",
          company_name: "",
          address: "",
          service_areas: "",
          specialties: "",
          about_me: "",
          facebook: "",
          twitter: "",
          linkedin: "",
          instagram: "",
          youtube: "",
          pinterest: "",
          vimeo: "",
        });
        setUsername('');
        setEmail('');
      } else {
        const result = await response.json(); // Parse JSON response for errors
        console.error("Error updating profile", result);
      }
    } catch (error) {
      console.error("Network error", error);
    }
  };

  return (
   
    <>
   <form>
      <div className="add-property">
        <div className="form-wrapper" style={{ boxShadow: "0px 1px 3px -1px" }}>
        <div className="form-control">
          <label className="form-label" htmlFor="username">
            Username
          </label>
          <input
            className="form-input"
            id="username"
            type="text"
            placeholder="Enter Your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-control">
            <label className="form-label">Fist Name </label>
            <input
              className="form-input"
              type="text"
              name="first_name"
              required
              placeholder="Enter your the Fist_name"
              value={formData.first_name}
              onChange={handleChange}
            />
          
          </div>
          <div className="form-control">
            <label className="form-label">Last Name </label>
            <input
              className="form-input"
              type="text"
              name="last_name"
              required
              placeholder="Enter your the Last_name"
              value={formData.last_name}
              onChange={handleChange}
            />
          
          </div>
          <div className="form-control">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <input
            className="form-input"
            id="email"
            type="text"
            placeholder="Enter Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
          <div className="form-control">
            <label className="form-label">Display Name</label>
            <input
              className="form-input"
              type="text"
              name="display_name"
              required
              placeholder="Enter your the display_name"
              value={formData.display_name}
              onChange={handleChange}
            />
          
          </div>
          <div className="form-control">
            <label className="form-label">position:</label>
            <input
              className="form-input"
              type="text"
              name="position"
              required
              placeholder="Enter your the position"
              value={formData.position}
              onChange={handleChange}
            />
          
          </div>
          <div className="form-control">
            <label className="form-label">License</label>
            <input
              className="form-input"
              type="text"
              name="license"
              required
              placeholder="Enter your the license"
              value={formData.license}
              onChange={handleChange}
            />
          
          </div>
          <div className="form-control">
            <label className="form-label">Mobile</label>
            <input
              className="form-input"
              type="text"
              name="mobile_number"
              required
              placeholder="Enter your the mobile_number"
              value={formData.mobile_number}
              onChange={handleChange}
            />
          
          </div>
          <div className="form-control">
            <label className="form-label">Whatsapp</label>
            <input
              className="form-input"
              type="text"
              name="whatsapp_number"
              required
              placeholder="Enter your the whatsapp_number"
              value={formData.whatsapp_number}
              onChange={handleChange}
            />
          
          </div>
          <div className="form-control">
            <label className="form-label">LineId</label>
            <input
              className="form-input"
              type="text"
              name="line_id"
              required
              placeholder="Enter your the line_id"
              value={formData.line_id}
              onChange={handleChange}
            />
          
          </div>
          <div className="form-control">
            <label className="form-label">Telegram</label>
            <input
              className="form-input"
              type="text"
              name="telegram"
              required
              placeholder="Enter your the telegram"
              value={formData.telegram}
              onChange={handleChange}
            />
          
          </div>
          <div className="form-control">
            <label className="form-label">Tex Number</label>
            <input
              className="form-input"
              type="text"
              name="text_number"
              required
              placeholder="Enter your the text_number"
              value={formData.text_number}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <label className="form-label">phone_number</label>
            <input
              className="form-input"
              type="text"
              name="phone_number"
              required
              placeholder="Enter your the phone_number"
              value={formData.phone_number}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <label className="form-label">Fax Number </label>
            <input
              className="form-input"
              type="text"
              name="fax_number"
              required
              placeholder="Enter your the fax_number"
              value={formData.fax_number}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <label className="form-label">Language</label>
            <select
              className="form-input"
              name="language"
              value={formData.language}
              onChange={handleChange}
            >
              <option value="">Select language</option>
              <option value=" English"> English</option>
              <option value="Spanish">Spanish</option>
              <option value="French">French</option>
              <option value="German">German</option>

            </select>
          </div>
          <div className="form-control">
            <label className="form-label">Company Name </label>
            <input
              className="form-input"
              type="text"
              name="company_name"
              required
              placeholder="Enter your the company_name"
              value={formData.company_name}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <label className="form-label">Address</label>
            <input
              className="form-input"
              type="text"
              name="address"
              required
              placeholder="Enter your the address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <label className="form-label">Service Areas</label>
            <input
              className="form-input"
              type="text"
              name="service_areas"
              required
              placeholder="Enter your the service_areas"
              value={formData.service_areas}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <label className="form-label">Specialties</label>
            <input
              className="form-input"
              type="text"
              name="specialties"
              required
              placeholder="Enter your the specialties"
              value={formData.specialties}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <label className="form-label">About Me</label>
            <textarea
              className="form-input"
              style={{ display: "flex", height: "165px" }}
              type="text"
              required
              name="about_me"
              placeholder="Desciption"
              value={formData.about_me}
              onChange={handleChange}
            />
           
          </div>        
        </div>
        <div className="form-wrapper" style={{boxShadow:'0px 1px 3px -1px',marginTop:'12px', borderRadius:'9px'}}>
        <span className='form-span'>Social</span>
        <div className="form-control">
            <label className="form-label">Facebook</label>
            <input
              className="form-input"
              type="text"
              name="facebook"
              required
              placeholder="Enter your the facebook"
              value={formData.facebook}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <label className="form-label">Twitter</label>
            <input
              className="form-input"
              type="text"
              name="twitter"
              required
              placeholder="Enter your the twitter"
              value={formData.twitter}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <label className="form-label">Linkedin</label>
            <input
              className="form-input"
              type="text"
              name="linkedin"
              required
              placeholder="Enter your the linkedin"
              value={formData.linkedin}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <label className="form-label">Instagram</label>
            <input
              className="form-input"
              type="text"
              name="instagram"
              required
              placeholder="Enter your the instagram"
              value={formData.instagram}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <label className="form-label">Youtube</label>
            <input
              className="form-input"
              type="text"
              name="youtube"
              required
              placeholder="Enter your the youtube"
              value={formData.youtube}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <label className="form-label">Pinterest</label>
            <input
              className="form-input"
              type="text"
              name="pinterest"
              required
              placeholder="Enter your the pinterest"
              value={formData.pinterest}
              onChange={handleChange}
            />
          </div>
          <div className="form-control">
            <label className="form-label">Vimeo</label>
            <input
              className="form-input"
              type="text"
              name="vimeo"
              required
              placeholder="Enter your the vimeo"
              value={formData.vimeo}
              onChange={handleChange}
            />
          </div>
        </div>
        
          <div className="form-wrapper" style={{boxShadow:'0px 1px 3px -1px',marginTop:'12px', borderRadius:'9px'}}>
    
    <div className='form-control'>
      <Button
       className='form-submit'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleNext}
      >
       Submit
      </Button>
    </div>


      </div>
      </div>
    </form>
    </>
  )
}

export default EditProfile