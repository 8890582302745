import React, { useState, useEffect } from "react";
import { FaUserLarge } from "react-icons/fa6";
import { Link } from "react-router-dom";

const AgentPage = () => {
  const [agents, setAgents] = useState([]);
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // Function to toggle form visibility
  const handleLocationClick = () => {
    setShowSearchForm((prevState) => !prevState);
  };

  useEffect(() => {
    const fetchAgents = async () => {
      // const userId = JSON.parse(localStorage.getItem("user"))._id;
      const requestOptions = {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("bearer-token"),
          },
      };
      try {
      
          const response = await fetch(
              `${process.env.REACT_APP_backend_server_url}/users/roleAgents`,
              requestOptions
          );
        
     
          if (response.ok && response.status == 200) {
            const result = await response.json();
          
         
            console.log("Agent data:", result);
            setAgents(result);
          }
          
          else{
              console.log(
                "Profile Not Found"
              )
          }
      } catch (error) {
      
          console.error("Fetch error:", error);
      }
  };
  
  fetchAgents();
}, []);

  // Filter agents by search query
  const filteredAgents = agents.filter((agent) =>
    agent.userprofiles[0]?.display_name
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <div className="agentsPage customPageWidth section-margin">
      <div className="container-agent">
        <h1 className="page-title">Agents</h1>
      </div>

      {/* Search Bar */}
      <div className="search-container" onClick={handleLocationClick}>
        <input
          type="text"
          placeholder="🔍 Type Agent's Name Here....."
          className="search-input"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {showSearchForm && (
        <div className="search-form-agent">
          <h3>Find Agent</h3>
          <input
            type="text"
            className="search-bar-agent"
            placeholder="Type agent name here"
            autoFocus
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button className="search-button-agent">
            <span>Search Agent</span>
          </button>
        </div>
      )}

      {/* Agents List */}
      <div className="block-container">
        {filteredAgents.map((agent, index) => (
          <div className="block" key={index}>
            <h1 className="word">{agent.userprofiles[0]?.first_name?.[0] || "A"}</h1>
            <div className="end" id="agentName">
              <div className="section-page">
                <img
                  alt={`Image of ${agent.userprofiles[0]?.first_name || "Agent"}`}
                  height="40"
                  src={agent.addimages[0]?.profile_image || "https://via.placeholder.com/40"}
                  width="40"
                />
                <div className="text">
                  <div className="addproperty-text">
                    <Link
                      to={`/ProfilePage/${agent.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <span style={{ display: "flex", color: "black" }}>
                        {agent.userprofiles[0]?.first_name || "Agent Name"}
                      </span>
                    </Link>
                  </div>
                  {/* <div className="email">
                    <a
                      href={`mailto:${agent.email}`}
                      style={{ textDecoration: "none", color: "gray" }}
                    >
                      {agent.email}
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AgentPage;
