import React, { useEffect, useRef, useState } from "react";
import Flickity from "flickity";
import "flickity/css/flickity.css";
import { FaRulerCombined, FaMapMarkerAlt, FaBed, FaBath } from "react-icons/fa";

const FlickitySlider = () => {
  const sliderRef = useRef(null); // Reference to the slider DOM element
  const flickityInstance = useRef(null); // Reference to the Flickity instance

  const [random, setRandom] = useState([]);
  const testItems = [
    { id: 1, title: "Item 1", image: "https://via.placeholder.com/200" },
    { id: 2, title: "Item 2", image: "https://via.placeholder.com/200" },
    { id: 3, title: "Item 3", image: "https://via.placeholder.com/200" },
    { id: 4, title: "Item 4", image: "https://via.placeholder.com/200" },
    { id: 5, title: "Item 5", image: "https://via.placeholder.com/200" },
  ];

  const initializeFlickity = () => {
    if (sliderRef.current) {
      // Destroy existing instance to avoid duplication
      if (flickityInstance.current) {
        flickityInstance.current.destroy();
      }

      // Create a new Flickity instance with updated options
      flickityInstance.current = new Flickity(sliderRef.current, {
        cellAlign: "center", // Center-align the active slide
        contain: false, // Allow slides to flow outside the container
        pageDots: true, // Enable page dots for navigation
        wrapAround: true, // Allow infinite scrolling
        groupCells: 1, // Only show one cell at a time
      });
    }
  };

  // const fetchProperties = async () => {
  //   const requestOptions = {
  //     method: "GET",
  //     headers: {
  //       "ngrok-skip-browser-warning": "any",
  //       authorization: "Bearer " + localStorage.getItem("bearer-token"),
  //     },
  //   };

  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_backend_server_url}/properties/random`,
  //       requestOptions
  //     );

  //     if (response.ok) {
  //       const result = await response.json();
  //       if (result.properties) {
  //         setRandom(result.properties);

  //         // Reinitialize Flickity after setting new data
  //         setTimeout(() => {
  //           initializeFlickity();
  //         }, 100);
  //       }
  //     } else {
  //       const errorResult = await response.json();
  //       console.error("Error fetching properties:", errorResult);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching properties:", error);
  //   }
  // };



  const fetchProperties = async () => {
    const token = localStorage.getItem("bearer-token");
    const backendUrl =
      process.env.REACT_APP_backend_server_url || "https://fallback-backend-url.com";
  
    if (!token) {
      console.error("Bearer token is missing");
      return;
    }
  
    const requestOptions = {
      method: "GET",
      headers: {
        "ngrok-skip-browser-warning": "any",
        authorization: `Bearer ${token}`,
      },
    };
  
    try {
      console.log("Fetching properties...");
      console.log("Bearer token:", token);
      console.log("Backend URL:", backendUrl);
  
      const response = await fetch(`${backendUrl}/properties/random`, requestOptions);
  
      if (response.ok) {
        const result = await response.json();
        console.log("API response:", result);
  
        if (result.properties) {
          setRandom(result.properties);
        }
      } else {
        const errorResult = await response.json();
        console.error("Error response from server:", errorResult);
      }
    } catch (error) {
      console.error("Network or Fetch Error:", error);
    }
  };
  
  // useEffect(() => {
  //   // Fetch properties on component mount
  //   fetchProperties();

  //   // Cleanup Flickity instance on unmount
  //   return () => {
  //     if (flickityInstance.current) {
  //       flickityInstance.current.destroy();
  //     }
  //   };
  // }, []);

  useEffect(() => {
    // Simulate API call with static data
    setTimeout(() => {
      setRandom([
        {
          property_title: "Sample Property 1",
          property_type: "House",
          status: "For Sale",
          uploads: [{ media: "https://via.placeholder.com/400x200" }],
          address: [{ city: "City 1", state: "State 1", country: "Country 1" }],
          details: [{ bedrooms: 3, bathrooms: 2, size: 1200, size_prefix: "sq ft" }],
        },
      ]);
    }, 1000);
  }, []);
  

  return (
    <div>
      <div className="featured-properties customPageWidth section-margin">
        <div className="featured-properties-app">
          <h2>Featured Properties</h2>
          <div className="see-all">See All &gt;</div>
        </div>
        <div className="cards carousel main-carousel" ref={sliderRef}>
          {random.length > 0 ? (
            random.map((property, id) => (
              <div className="card" data-index={id} key={id}>
                <div className="card-mainImage">
                  <img
                    src={
                      property.uploads?.length > 0
                        ? property.uploads[0].media
                        : "https://via.placeholder.com/400x200"
                    }
                    alt={property.property_title}
                  />
                </div>
                <div className="card-content">
                  <p className="card-heading">{property.property_title}</p>
                  <div className="tags">
                    <span className="tag">{property.property_type}</span>
                    <div className="tag">{property.status}</div>
                  </div>
                  <div className="location card-info" style={{ marginTop: 6 }}>
                    {property.address?.length > 0 ? (
                      <>
                        <FaMapMarkerAlt style={{ color: "#ff3054" }} />
                        <span>{property.address[0].city}</span>
                        <span>{property.address[0].state}</span>
                        <span>{property.address[0].country}</span>
                      </>
                    ) : null}
                  </div>
                  <div className="more-info card-info">
                    <div className="descriptive">
                      {property.details?.length > 0 ? (
                        <>
                          <span>
                            <FaBed style={{ color: "#ff3054" }} />
                            {property.details[0].bedrooms}
                          </span>
                          <FaBath style={{ color: "#ff3054" }} />
                          <span>{property.details[0].bathrooms}</span>
                          <span>
                            <FaRulerCombined style={{ color: "#ff3054" }} />
                            {property.details[0].size}
                            {property.details[0].size_prefix || "sq ft"}
                          </span>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="card-price"></div>
                </div>
              </div>
            ))
          ) : (
            <p>No properties available.</p>
          )}
        </div>
      </div>
      <div className="carousel">
        {testItems.map((item) => (
          <div
            className="carousel-cell"
            key={item.id}
            style={{
              width: "800px",
              margin: "10px",
              borderRadius: "8px",
              overflow: "hidden",
              background: "#f4f4f4",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={item.image}
              alt={item.title}
              style={{
                width: "100%",
                height: "250px",
                objectFit: "cover",
              }}
            />
            <h3 style={{ padding: "10px 0", margin: 0 }}>{item.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FlickitySlider;
