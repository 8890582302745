import React, { useRef, useEffect, useState } from 'react';
import { IoIosStar } from "react-icons/io";
import { FaStarHalfAlt } from "react-icons/fa";
import Button from "@mui/material/Button";

const AddPropertyReview = ({ id }) => {
  const [formData, setFormData] = useState({
    comment: "",
    description: "",
    rating: 0,
  });
  useEffect(() => {
    const fetchPropertyReview = async () => {
    //   const userId = JSON.parse(localStorage.getItem("user"))._id;
      const requestOptions = {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("bearer-token"),
          },
      };
      try {
      
          const response = await fetch(
              `${process.env.REACT_APP_backend_server_url}/rating`,
              requestOptions
          );
          if (response.ok && response.status == 200) {
           debugger;
            const result = await response.json();
            console.log("rating create  data:", result);
          }
          
          else{
              console.log(
                "rating Not Found"
              )
          }
      } catch (error) {
      
          console.error("Fetch error:", error);
      }
  };
  
  fetchPropertyReview();
}, []);

  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRating = (value) => {
    setFormData((prev) => ({ ...prev, rating: value }));
  };


  const handleNext = async (event) => {
    event.preventDefault();

  
    const formDataObj = new FormData();
    formDataObj.append('comment', formData.comment || '');
    formDataObj.append('description', formData.description || '');
    formDataObj.append('rating', formData.rating || '');

    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        property_id: localStorage.properties,
        comment: formData.comment || '',
        description: formData.description || '',
        rating: formData.rating || '',
       

        user_id: JSON.parse(localStorage.user)._id
      }),
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("bearer-token"),
      },
    };

    try {
      debugger;
      const response = await fetch(
        `${process.env.REACT_APP_backend_server_url}/rating`, 
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        localStorage.setItem("rating", result._id);
        debugger;
        console.log("Rating created successfully", result.message);
        setSuccess(result.message);

        setFormData({
            comment: "",
            description: "",
            rating: "",
        });

      } else {
        const result = await response.json(); // Parse JSON response for errors
        console.error("Error updating rating", result);
      }
    } catch (error) {
      console.error("Network error", error);
    }
  };

  return (
    <>
      <form>
        <div className="add-property">
          <div className="form-wrapper" style={{ boxShadow: "0px 1px 3px -1px" }}>
            <div className="stars-review">
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  onClick={() => handleRating(star)}
                  style={{ cursor: "pointer" }}
                >
                  {formData.rating >= star ? (
                    <IoIosStar size={24} color="#FFD700" />
                  ) : (
                    <FaStarHalfAlt size={24} color="#ccc" />
                  )}
                </span>
              ))}
            </div>
            <div className="form-control">
              <label className="form-label">Title</label>
              <input
                className="form-input"
                type="text"
                name="comment"
                required
                placeholder="Enter your comment"
                value={formData.comment}
                onChange={handleChange}
              />
            </div>
            <div className="form-control">
              <label className="form-label">Review</label>
              <input
                className="form-input"
                type="text"
                name="description"
                required
                placeholder="Enter your review description"
                value={formData.description}
                onChange={handleChange}
              />
            </div>
          </div>

        
          <div className="form-wrapper" style={{boxShadow:'0px 1px 3px -1px',marginTop:'12px', borderRadius:'9px'}}>
    
    <div className='form-control'>
      <Button
       className='form-submit'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleNext}
      >
       Submit
      </Button>
    </div>


      </div>
        </div>
        {/* {success && <p style={{ color: "green" }}>Review submitted successfully!</p>}
        {error && <p style={{ color: "red" }}>{error}</p>} */}
      </form>
    </>
  );
};

export default AddPropertyReview;
