import React, { useState, useEffect } from "react";
import { FaRulerCombined, FaMapMarkerAlt, FaBed, FaBath } from "react-icons/fa";
import { Link } from "react-router-dom";

const PropertyResult = () => {
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchAllProperties = async () => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "any",
          authorization: "Bearer " + localStorage.getItem("bearer-token"),
        },
      };
  
      const response = await fetch(
        `${process.env.REACT_APP_backend_server_url}/properties/random`,
        requestOptions
      );
  
      if (!response.ok) {
        throw new Error("Failed to fetch properties");
      }
  
      const result = await response.json();
      const searchParams = JSON.parse(localStorage.getItem("searchData")) || {};
  
      console.log("Search Parameters:", searchParams);
      console.log("Fetched Properties:", result.properties);
  
      debugger;
      if (result.properties && result.properties.length > 0) {
        const filtered = result.properties.filter((property) => {
          const hasMatchingStatus =
            !searchParams.status || property.status === searchParams.status;
          const hasMatchingType =
            !searchParams.property_type || property.property_type === searchParams.property_type;
          const hasMatchingBedrooms =
            !searchParams.bedrooms ||
            (property.details?.[0]?.bedrooms || 0) === searchParams.bedrooms;
          const hasMatchingBathrooms =
            !searchParams.bathrooms ||
            (property.details?.[0]?.bathrooms || 0) === searchParams.bathrooms;
          const hasMatchingPostalCode =
            !searchParams.postal_code ||
            (property.address?.[0]?.postal_code || "") === searchParams.postal_code;
          const hasMatchingCity =
            !searchParams.city || (property.address?.[0]?.city || "") === searchParams.city;
          const hasMatchingState =
            !searchParams.state || (property.address?.[0]?.state || "") === searchParams.state;
          const hasMatchingPriceRange =
            !searchParams.priceRange ||
            (parseFloat(property.sale_rent_price || 0) >= searchParams.priceRange.min &&
              parseFloat(property.sale_rent_price || 0) <= searchParams.priceRange.max);
  
          return (
            hasMatchingStatus &&
            hasMatchingType &&
            hasMatchingBedrooms &&
            hasMatchingBathrooms &&
            hasMatchingPostalCode &&
            hasMatchingCity &&
            hasMatchingState &&
            hasMatchingPriceRange
          );
        });
  
        console.log("Filtered Properties:", filtered);
  
        setProperties(result.properties);
        setFilteredProperties(filtered);
      } else {
        console.warn("No properties found in API response");
        setProperties([]);
        setFilteredProperties([]);
      }
    } catch (err) {
      setError(err.message);
      console.error("Error fetching properties:", err.message);
    } finally {
      setLoading(false);
    }
  };
  
  

  useEffect(() => {
    fetchAllProperties();
  }, []);

  return (
    <div className="property-results customPageWidth section-margin">
      <div className="featured-properties-app">
        <h2 style={{ marginBottom: "12px" }}>Property Results</h2>
      </div>
      <div className="property-items">
        {loading ? (
          <p>Loading properties...</p>
        ) : error ? (
          <p>Error: {error}</p>
        ) : filteredProperties.length > 0 ? (
          filteredProperties.map((property, id) => (
            <div className="item" data-index={id} key={property._id}>
              <Link
                to={`/detailproperty/${property._id}`}
                style={{ textDecoration: "none" }}
              >
                <div className="property-image">
                  <img
                    src={
                      property.uploads?.length > 0
                        ? property.uploads[0].media
                        : "https://via.placeholder.com/400x200"
                    }
                    alt={property.property_title}
                  />
                </div>
                <div className="card-content">
                  <p className="card-heading">{property.property_title}</p>
                </div>
                <div className="property-info">
                  <p className="price">
                    ${property.sale_rent_price}
                  </p>
                  <div className="descriptive">
                    {property.details?.length > 0 && (
                      <>
                        <span>
                          <FaBed /> {property.details[0].bedrooms}
                        </span>
                        <span>
                          <FaBath /> {property.details[0].bathrooms}
                        </span>
                        <span>
                          <FaRulerCombined /> {property.details[0].size}{" "}
                          {property.details[0].size_prefix || "sq ft"}
                        </span>
                      </>
                    )}
                  </div>
                  <p className="address">
                    {property.address?.length > 0 && (
                      <>
                        <FaMapMarkerAlt />
                        <span>{property.address[0].city}</span>,{" "}
                        <span>{property.address[0].state}</span>
                      </>
                    )}
                  </p>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <p>No properties match your search criteria.</p>
        )}
      </div>
    </div>
  );
};

export default PropertyResult;
