import React, { useState } from 'react';
import { FaSort, FaCheck, FaClock, FaTag, FaRulerCombined } from 'react-icons/fa';

const SortBy = () => {
  const [selectedSort, setSelectedSort] = useState('newest');
  const [isOpen, setIsOpen] = useState(false); // State to manage open/close

  // Function to toggle the open/close state
  const toggleSortOptions = () => {
    setIsOpen(!isOpen);
  };

  const handleSortChange = (event) => {
    setSelectedSort(event.target.value);
    toggleSortOptions(); // Close the popup after selection
  };

  return (
    <div className="sort-container">
      {/* Sort header that toggles the visibility of options */}
      <div className="sort-header" onClick={toggleSortOptions} style={{ cursor: 'pointer' }}>
        <FaSort />
        <h2>Sort by</h2>
        <FaCheck className="check-icon" />
      </div>
      
      {/* Conditionally render the sort options based on the isOpen state */}
      {isOpen && (
        <div className="sort-options">
          <div className="sort-option">
            <FaClock style={{color:"#e91e63"}}/>
            <label htmlFor="newest">Newest</label>
            <input
              type="radio"
              id="newest"
              name="sort"
              value="newest"
              checked={selectedSort === 'newest'}
              onChange={handleSortChange}
            />
          </div>
          
          <div className="sort-option">
            <FaClock style={{color:"#e91e63"}}/>
            <label htmlFor="oldest">Oldest</label>
            <input
              type="radio"
              id="oldest"
              name="sort"
              value="oldest"
              checked={selectedSort === 'oldest'}
              onChange={handleSortChange}
            />
          </div>
          
          <div className="sort-option">
            <FaTag style={{color:"#e91e63"}}/>
            <label htmlFor="price-low-high">Price (low to high)</label>
            <input
              type="radio"
              id="price-low-high"
              name="sort"
              value="price-low-high"
              checked={selectedSort === 'price-low-high'}
              onChange={handleSortChange}
            />
          </div>
          
          <div className="sort-option">
            <FaTag style={{color:"#e91e63"}}/>
            <label htmlFor="price-high-low">Price (high to low)</label>
            <input
              type="radio"
              id="price-high-low"
              name="sort"
              value="price-high-low"
              checked={selectedSort === 'price-high-low'}
              onChange={handleSortChange}
            />
          </div>
          
          <div className="sort-option">
            <FaRulerCombined style={{color:"#e91e63"}}/>
            <label htmlFor="area-low-high">Area (low to high)</label>
            <input
              type="radio"
              id="area-low-high"
              name="sort"
              value="area-low-high"
              checked={selectedSort === 'area-low-high'}
              onChange={handleSortChange}
            />
          </div>
          
          <div className="sort-option">
            <FaRulerCombined style={{color:"#e91e63"}}/>
            <label htmlFor="area-high-low">Area (high to low)</label>
            <input
              type="radio"
              id="area-high-low"
              name="sort"
              value="area-high-low"
              checked={selectedSort === 'area-high-low'}
              onChange={handleSortChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SortBy;
