import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

const SubListing = () => {
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();

  const [inputFields, setInputFields] = useState([
    {
      plan_title: '',
      bedrooms: '',
      bathrooms: '',
      property_title: '',
      postfix: '',
      price: '',
      price_postfix: '',
      property_type: '',
      availability: ''
    }
  ]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputFields = [...inputFields];
    newInputFields[index][name] = value;
    setInputFields(newInputFields);
  };
  const [success, setSuccess] = useState(null);

  const handleAddInputField = () => {
    const newInputField = {
      plan_title: '',
      bedrooms: '',
      bathrooms: '',
      property_title: '',
      postfix: '',
      price: '',
      price_postfix: '',
      property_type: '',
      availability: ''
    };
    setInputFields([...inputFields, newInputField]);
  };

  const handleRemoveInputField = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
  };

  const handleClick = () => {
    console.log('Form data:', inputFields);
  };
    
  const inputField = {
    plan_title: '',
    property_title: '',
    property_type: '',
    availability: ''
  };
  const validateForm = () => {
    const errors = {};
    inputFields.forEach((field) => {
      if (!field.plan_title) {
        errors[`plan_title`] = "Plan title is required";
      }
      if (!field.bedrooms) {
        errors[`bedrooms`] = "Bedrooms are required";
      }
      if (!field.bathrooms) {
        errors[`bathrooms`] = "Bathrooms are required";
      }
  
    });
    return errors;
  };
  const handleNext = async (event) => {
    event.preventDefault();
   
    const errors = validateForm(inputField);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
  
    console.log("Form data submitted:", inputFields);

    const formDataObj = new FormData();
    inputFields.forEach((inputField) => {
      formDataObj.append('plan_title', inputField.plan_title);
      formDataObj.append('bedrooms', inputField.bedrooms);
      formDataObj.append('bathrooms', inputField.bathrooms);
      formDataObj.append('property_title', inputField.property_title);
      formDataObj.append('postfix', inputField.postfix);
      formDataObj.append('price', inputField.price);
      formDataObj.append('price_postfix', inputField.price_postfix);
      formDataObj.append('property_type', inputField.property_type);
      formDataObj.append('availability', inputField.availability);
    });
    console.log('Form data:', [...formDataObj.entries()]);
  
    formDataObj.append("property_id", localStorage.getItem("properties"));
    formDataObj.append("user_id", JSON.parse(localStorage.getItem("user"))._id);
  
   
   
    for (let pair of formDataObj.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        property_id: localStorage.getItem("properties"),
        plan_title: inputFields [0].plan_title,
        bedrooms: inputFields[0].bedrooms,
        bathrooms: inputFields[0].bathrooms,
        property_title: inputFields[0].property_title,
        postfix: inputFields[0].postfix,
        price: inputFields[0].price,
        price_postfix: inputFields[0].price_postfix,
        property_type: inputFields[0].property_type,
        availability: inputFields[0].availability,
        user_id: JSON.parse(localStorage.getItem("user"))._id,       
        
      }),
      headers: {
        "Authorization": 'Bearer ' + localStorage.getItem("bearer-token"),
         "Content-Type": "application/json" 
      },
    };

  const response = await fetch(
    `${process.env.REACT_APP_backend_server_url}/sublist`,
    requestOptions
  );
    // const response = await fetch('https://ae4f-139-135-43-100.ngrok-free.app/sublist', requestOptions);
  
    if (response.ok) {
      const result = await response.json();
      console.log('Property added successfully', result); 
      
      setSuccess(result.message);
      // Reset form fields
      navigate("/propertyresults");
      setInputFields([
        {
          plan_title: '',
          bedrooms: '',
          bathrooms: '',
          property_title: '',
          postfix: '',
          price: '',
          price_postfix: '',
          property_type: '',
          availability: ''
        }
      ]);

    } else {
      const result = await response.text();
      console.error('Error adding property', result);
    }
  
  };
  return (
    <form>
    <div className="add-property">
    <div className="form-wrapper" style={{ boxShadow: '0px 1px 3px -1px', marginTop: '12px', borderRadius: '9px' }}>
      <span className="form-span">Sub Listing</span>
      <div className="form-control">
      {inputFields.map((inputField, index) => (
        
        <div key={index}>
  <div className='from-plan'>
    <div>
      <label className='form-label'>Plan title:</label>
      <input
        className='form-input' 
        style={{display:'flex',width:'306px'}}
        type="text"
        name="plan_title"
        value={inputField.title}
        onChange={(event) => handleInputChange(index, event)}
        placeholder="plan_title"
      />
       {formErrors.plan_title && (
              <span className="error-app" >{formErrors.plan_title}</span>
            )}
      </div>
       <button
           className='form-crose' style={{height:'0px'}}
            onClick={() => handleRemoveInputField(index)}
          >
            ×
          </button>
          </div>
      <div className="form-event" style={{gap:'22px',marginTop:'12px'}} >
          <div>
            <label className="form-label">Bedrooms:</label>
            <input
              className="form-title"
              type="number"
              name="bedrooms"
              value={inputField.bedrooms}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="bedrooms"
            />
          </div>
          <div>
            <label className="form-label">Bathrooms:</label>
            <input
              className="form-title"
              type="number"
              name="bathrooms"
              value={inputField.bthrooms}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="bthrooms"
            />
              </div>
          </div>
          <div className="form-event" style={{gap:'22px',marginTop:'12px'}} >
          <div>
            <label className="form-label">Property Title:</label>
            <input
              className="form-title"
              type="text"
              name="property_title"
              value={inputField.property_title}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="property_title"
            />
              {formErrors.property_title && (
              <span className="error-app" >{formErrors.property_title}</span>
            )}
          </div>
          <div>
            <label className="form-label">postfix:</label>
            <input
              className="form-title"
              type="text"
              name="postfix"
              value={inputField.postfix}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="postfix"
            />
              </div>
          </div>
          <div className="form-event" style={{gap:'22px',marginTop:'12px'}} >
          <div>
            <label className="form-label">price:</label>
            <input
              className="form-title"
              type="text"
              name="price"
              value={inputField.price}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="price"
            />
          </div>
          <div>
            <label className="form-label">Pirce postfix:</label>
            <input
              className="form-title"
              type="text"
              name="price_postfix"
              value={inputField.price_postfix}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="price_postfix"
            />
              </div>
          </div>
          <div className="form-event" style={{gap:'22px',marginTop:'12px'}} >
          <div>
            <label className="form-label">Property Type:</label>
            <input
              className="form-title"
              type="text"
              name="property_type"
              value={inputField.property_type}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="property_type"
            />
             {formErrors.property_type && (
              <span className="error-app" >{formErrors.property_type}</span>
            )}
          </div>
          <div>
            <label className="form-label">availability:</label>
            <input
              className="form-title"
              type="text"
              name="availability"
              value={inputField.availability}
              onChange={(event) => handleInputChange(index, event)}
              placeholder="availability"
            />
             {formErrors.availability && (
              <span className="error-app" >{formErrors.availability}</span>
            )}
              </div>
          </div>
        
        
        </div>
      ))}
      <button className="add_new" onClick={handleAddInputField}>
        Add New
      </button>
    </div>
      </div>
      <div className="form-wrapper" style={{boxShadow:'0px 1px 3px -1px',marginTop:'12px', borderRadius:'9px'}}>
      <div style={{display: 'flex',
    justifyContent: 'space-between'}}>
<div  className="form-containe">
      <Button
      className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleClick}
      >
        Back
      </Button>
    </div>
    <div className='form-contain'>
      <Button
       className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleNext}
      >
       Next
      </Button>
    </div>
</div>

      </div>
      </div>
      </form>
  )
}

export default SubListing