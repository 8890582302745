import React, { useState } from "react";
import Flickity from "react-flickity-component";
import { FaRulerCombined } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { LuArrowDownUp } from "react-icons/lu";
import { FaSlidersH, FaRegKeyboard } from "react-icons/fa";
import { IoBedOutline } from "react-icons/io5";
import { LiaBathSolid } from "react-icons/lia";
import { MdPool } from "react-icons/md";
import { FaSort, FaCheck, FaClock, FaTag } from "react-icons/fa";
import { Link, Navigate } from "react-router-dom";
import { SlArrowRight } from "react-icons/sl";
import CustomRangeSlider from "./CustomRangeSlider";
import { useRef, useEffect } from "react";
import { FaMapMarkerAlt, FaBed, FaBath } from "react-icons/fa";
import { IoIosPricetags } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import {  useNavigate } from "react-router-dom";

const FeatureProperty = () => {
  const flickityOptions = {
    initialIndex: 0,
    cellAlign: "left",
    contain: true,
    pageDots: true,
    wrapAround: true,
  };
  // const [bedrooms, setBedrooms] = useState(1);
  // const [bathrooms, setBathrooms] = useState(1);
  // const [poolOption, setPoolOption] = useState("All");
  // const [keyword, setKeyword] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  // const [priceRange, setPriceRange] = useState({ min: 0, max: 1000000 });
  const navigate = useNavigate(); // Initialize useNavigate

  const [activeButton, setActiveButton] = useState("location");
  const [activeButtoniwt, setActiveButtoniwt] = useState("all");

  const iwanttohandleButton = (buttonId) => {
    setActiveButtoniwt(buttonId);
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const sidebarRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false); 
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [showSearchForm, setShowSearchForm] = useState(false);
  const handleLocationClick = () => {
    setShowSearchForm((prevState) => !prevState);
  };
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchPropertyTypes = async (option) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "ngrok-skip-browser-warning": "any",
        authorization: "Bearer " + localStorage.getItem("bearer-token"),
      },
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_backend_server_url}/properties/property-types?status=${option}`,
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        setPropertyTypes(result["propertyTypes"]);

        console.log("Fetched property types:", result);
      }
    } catch (error) {
      setError("Fetch error: " + error.message);
      console.error("Fetch error:", error);
    }
  };

  // const handleButtonTypeClick = async (option) => {
  //   setSelectedOption(option);
  //   setLoading(true);
  //   setError(null); 
  //   fetchPropertyTypes(option);
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     propertyType: type,
  // }));
  // };
  const handleButtonTypeClick = async (option) => {
    setSelectedOption(option);
    setLoading(true);
    setError(null); 

    await fetchPropertyTypes(option);

    setFormData((prevData) => ({
        ...prevData,
        propertyType: option, 
      status: option,
    }));
};
  const [selectedOption, setSelectedOption] = useState("For Sale");
  const [selectedType, setSelectedType] = useState("office");
  const handleButtonClick = (type) => {
    setSelectedType(type);
    setBedrooms(null);
    setBathrooms(null);
    setPoolOption(null);
    setKeyword("");
    setFormData((prevData) => ({
      ...prevData,
      propertyType: type,
      status: type,
  }));
    setFormData({ bedrooms: null, bathrooms: null, pool: "null", keyword: "" });
  };
  const [formData, setFormData] = useState({
    status: "",
    state: "",
    city: "",
    postal_code: "",
    bedrooms: 1,
    bathrooms: 1,
    priceRange: { min: 0, max: 100000 },
    poolOption: "All",
    keyword: "",
    propertyType: "", // Add propertyType to formData
});
// const [selectedType, setSelectedType] = useState("");

// const handleButtonTypeClick = (type) => {
//     setSelectedType(type);
   
// };

  const states = [
    { value: "Alabama", label: "Alabama" },
    { value: "Alaska", label: "Alaska" },
    { value: "Arizona", label: "Arizona" },
    { value: "Wyoming", label: "Wyoming" },
  ];
 
  const handlePoolButtonClick = (option) => {
    setPoolOption(option);
    setFormData({ ...formData, poolOption: option });
  };

  const handlePriceRangeChange = (min, max) => {
    debugger;
    setPriceRange({ min, max });

    setFormData({ ...formData, priceRange: { min, max } });
  };

  const mapStyles = {
    height: "100vh",
    width: "100%",
  };

  const cities = [
    { value: "kasur", label: "Kasur" },
    { value: "Lahore", label: "Lahore" },
    { value: "Islambed", label: "Islambed" },
    { value: "Multan", label: "Multan" },
  ];


  const handleSearch = () => {
    console.log("Original FormData:", formData);

    // debugger;
    const updatedFormData = {
        ...formData,
        status: formData.status ||"",
        state: formData.state ||"",
        postal_code: formData.postal_code ||"",
        bedrooms: bedrooms || "",
        bathrooms: bathrooms || "",
        poolOption: poolOption || "",
        keyword: keyword || "",
        priceRange: priceRange || "",
        propertyType: selectedType || "", 
    };

    try {
      navigate("/search");
        localStorage.setItem("searchData", JSON.stringify(formData));
        console.log("Search Values Saved:", updatedFormData);
    } catch (error) {
        console.error("Failed to save data to localStorage:", error);
    }
};
useEffect(() => {
  const savedData = getSavedSearchData();
  if (savedData) {
      setFormData(savedData);
      setSelectedOption(savedData.status || ""); // Restore selected option
      // Restore other states as needed
  }
}, []);
const getSavedSearchData = () => {
    try {
        const savedData = localStorage.getItem("searchData");
        if (savedData) {
            return JSON.parse(savedData); 
        }
    } catch (error) {
        console.error("Failed to parse saved data:", error);
    }
    return null; 
};

const [status, setStatus] = useState(""); // Define the status state

const [state, setState] = useState(""); 
const [postal_code, setPostalCode] = useState(""); 
const [bedrooms, setBedrooms] = useState(""); 
const [bathrooms, setBathrooms] = useState(""); 
const [poolOption, setPoolOption] = useState(""); 
const [keyword, setKeyword] = useState(""); 
const [priceRange, setPriceRange] = useState({ min: 0, max: 100000 }); 

useEffect(() => {
  const savedData = getSavedSearchData();
  if (savedData) {
      setFormData(savedData);
      setStatus(savedData.status || "");
      setState(savedData.state || "");
      setPostalCode(savedData.postal_code || "");
      setBedrooms(savedData.bedrooms || "");
      setBathrooms(savedData.bathrooms || "");
      setPoolOption(savedData.poolOption || "");
      setKeyword(savedData.keyword || "");
      setPriceRange(savedData.priceRange || "");
      setSelectedType(savedData.propertyType || ""); // Restore selected property type
  }
}, []);


  const togglePopup = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const [random, setRandom] = useState([]);
  const fetchProperties = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "ngrok-skip-browser-warning": "any",
        authorization: "Bearer " + localStorage.getItem("bearer-token"),
      },
    };

    const response = await fetch(
      `${process.env.REACT_APP_backend_server_url}/properties/random`,
      requestOptions
    );

    if (response.ok) {
      const result = await response.json();
      if (result.properties) {
        setRandom([]);
        setRandom((prevProperties) => [
          ...prevProperties,
          ...result.properties,
        ]);
        console.log("Schedule added successfully", result.properties);
      }
    } else {
      const result = await response.json();
      console.error("Error adding schedule", result);
    }
  };

  const [isPopupType, setIsPopupType] = useState(false);
  const [selectedSort, setSelectedSort] = useState("newest");
  const popupRef = useRef(null);
  const handleSortChange = (event) => {
    setSelectedSort(event.target.value);
  };
  const toggleTypePopup = () => {
    setIsPopupType((prev) => !prev);
  };
  useEffect(() => {
    fetchProperties();
    fetchPropertyTypes("For Sale");
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupType(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleStateChange = (e) => {
    setFormData((prevData) => ({
        ...prevData,
        state: e.target.value,
    }));
};

const handlePostalCodeChange = (e) => {
    setFormData((prevData) => ({
        ...prevData,
        postal_code: e.target.value,
    }));
};

  return (
    <>
      <div className="search-container">
        <div className="search-box">
          <IoSearch style={{ width: "20px", height: "20px", color: "red" }} />
          <input type="text" placeholder="Enter Address or Zip Code" />
        </div>
        <div className="search-buttons">
          <button onClick={togglePopup}>
            {" "}
            <FaSlidersH style={{ width: "20px", height: "20px" }} />
          </button>
          <button onClick={toggleTypePopup} style={{ cursor: "pointer" }}>
            <div className="addproperty-text">
              <LuArrowDownUp style={{ width: "20px", height: "20px" }} />
            </div>
          </button>
        </div>
      </div>
    
      {isPopupOpen && (
        <div id="customModal" className="custom-filters-popup">
          <div id="myModal" className="modal">
          
            <div className="modal-content">
              <div className="modal-header">
                <button className="close" onClick={closePopup}>
                  &times;
                </button>
                <h2>Filters</h2>
              </div>
              <div className="modal-body">
                <div className="modal-filter">
                  <div className="heading">
                    <div className="icon">
                   
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        width="20"
                        height="20"
                      >
                        <path
                          fill="#ff0000"
                          d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"
                        ></path>
                      </svg>
                    </div>
                    <p className="text">I want to</p>
                  </div>
                  <div className="content-app">
            <div className="radio-button">
                <button 
                    className={`radio-button-item ${selectedOption === "For Sale" ? "active" : ""}`} 
                    onClick={() => handleButtonTypeClick("For Sale")}
                >
                    Buy
                </button>
                <button 
                    className={`radio-button-item ${selectedOption === "Rent" ? "active" : ""}`} 
                    onClick={() => handleButtonTypeClick("Rent")}
                >
                    Rent
                </button>
                <button 
                    className={`radio-button-item ${selectedOption === "Vacation Rental" ? "active" : ""}`} 
                    onClick={() => handleButtonTypeClick("Vacation Rental")}
                >
                    Vacation Rental
                </button>
            </div>
            {/* Add other input fields and a search button */}
        </div>
                </div>

                <div className="location-filter-container">
               
                  <div
                    className="modal-filter-app"
                    id="locator"
                    data-type="location"
                    onClick={handleLocationClick}
                  >
                    <div className="heading">
                      <div
                        className="icon"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FaLocationDot style={{ color: "red" }} />
                      </div>
                      <p className="text">
                        <span className="highlight">Location</span>
                        <span className="main">Please Select</span>
                      </p>
                    </div>

                    <SlArrowRight />
                  </div>
                  {showSearchForm && (
                    <>
                                <div className="form-control">
                <label className="form-label-page">State</label>
                <select
                    className="search-bar"
                    value={formData.state}
                    onChange={handleStateChange}
                >
                    <option value="">Select a state</option>
                    {states.map((state) => (
                        <option key={state.value} value={state.value}>
                            {state.label}
                        </option>
                    ))}
                </select>
            </div>

            <div className="form-control">
                <label className="form-label-page">Zip Code</label>
                <input
                    className="search-bar"
                    type="text"
                    value={formData.postal_code}
                    onChange={handlePostalCodeChange}
                    placeholder="Enter the Zip code"
                />
            </div>


                    </>
                  )}
                </div>
                <div className="modal-filter">
                  <div className="heading">
                    <div className="icon">
                      <FaRulerCombined style={{ color: "#ff3054" }} />
                    </div>
                    <p className="text">Property Types</p>
                  </div>
                  <div className="content">
                    <div className="radio-button-type">
                      {propertyTypes.length > 0
                        ? propertyTypes.map((type) => (
                            <button
                              key={type}
                              className={`radio-button-app ${
                                selectedType === type ? "active-app" : ""
                              }`}
                              onClick={() => handleButtonClick(type)}
                            >
                              {type.charAt(0).toUpperCase() +
                                type.slice(1).replace(/-/g, " ")}
                            </button>
                          ))
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="modal-filter" id="price-range">
                  <div className="heading">
                    <div className="icon">
                      <IoIosPricetags style={{ color: "red" }} />
                    </div>
                    <p className="text">Price Range</p>
                  </div>
                  <div className="content">
                    <CustomRangeSlider
                      min={0}
                      max={100000}
                      step={10000}
                      trackColor="#00ff00"
                      thumbColor="#0000ff"
                      onChange={handlePriceRangeChange} 
                    />
                  </div>
                </div>

                {/* Bedrooms */}
                {(selectedType === "Shop" ||
                  selectedType === "Residential" ||
                  selectedType === "Apartment" ||
                  selectedType === "Cando" ||
                  selectedType === "Multi Family Home" ||
                  selectedType === "Single Family Hom" ||
                  selectedType === "Home" ||
                  selectedType === "Villa" ||
                  selectedType === "Studio") && (
                  <>
                    <div className="filter-item">
                      <div className="heading">
                        <div className="icon">
                          <IoBedOutline
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "red",
                            }}
                          />
                        </div>
                        <p className="text">Bedrooms</p>
                      </div>
                      <div className="button-group">
                        {[1, 2, 3, 4, 5, 6].map((bedroom) => (
                          <button
                            key={bedroom}
                            className={`btn ${
                              bedrooms === bedroom ? "selected" : ""
                            }`}
                            onClick={() => {
                              setBedrooms(bedroom);
                              setFormData({ ...formData, bedrooms: bedroom }); // Update formData
                            }}
                          >
                            {bedroom}
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* Bathrooms */}
                    <div className="filter-item">
                      <div className="heading">
                        <div className="icon">
                          <LiaBathSolid
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "red",
                            }}
                          />
                        </div>
                        <p className="text">Bathrooms</p>
                      </div>
                      <div className="button-group">
                        {[1, 2, 3, 4, 5, 6].map((bathroom) => (
                          <button
                            key={bathroom}
                            className={`btn ${
                              bathrooms === bathroom ? "selected" : ""
                            }`}
                            onClick={() => {
                              setBathrooms(bathroom);
                              setFormData({ ...formData, bathrooms: bathroom }); // Update formData
                            }}
                          >
                            {bathroom}
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* Pool */}
                    <div className="filters-modal">
                      {/* Pool Section */}
                      <div className="section">
                        <div className="heading">
                          <div className="icon">
                            <MdPool
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "red",
                              }}
                            />
                          </div>
                          <p className="text">Pool</p>
                        </div>
                        <div className="button-group">
                          {["All", "Yes Pool", "No Pool"].map((option) => (
                            <button
                              key={option}
                              className={poolOption === option ? "active" : ""}
                              onClick={() => handlePoolButtonClick(option)}
                            >
                              {option}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* Keywords */}
                    <div className="section">
                      <div className="heading">
                        <div className="icon">
                          <FaRegKeyboard
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "red",
                            }}
                          />
                        </div>
                        <p className="text">Keywords</p>
                      </div>
                      <div className="input-group">
                        <input
                          type="text"
                          value={keyword}
                          onChange={(e) => {
                            setKeyword(e.target.value);
                            setFormData({
                              ...formData,
                              keyword: e.target.value,
                            }); // Update formData with the keyword
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
                {/* Action Buttons */}
                <div className="action-buttons">
                  <button
                    className="reset"
                    onClick={() => {
                      setKeyword("");
                      setPoolOption("All");
                    }}
                  >
                    Reset
                  </button>
                  <button onClick={handleSearch} className="search">
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Conditionally render the sort options based on the isOpen state */}
      <div>
        {isPopupType && (
          <div ref={popupRef} className="sort-container">
            <div className="sort-options">
              <div className="sort-header" style={{ cursor: "pointer" }}>
                <FaSort />
                <h2>Sort by</h2>
                <FaCheck className="check-icon" />
              </div>

              <div className="sort-option">
                <FaClock style={{ color: "#e91e63" }} />
                <label htmlFor="newest">Newest</label>
                <input
                  type="radio"
                  id="newest"
                  name="sort"
                  value="newest"
                  checked={selectedSort === "newest"}
                  onChange={handleSortChange}
                />
              </div>

              <div className="sort-option">
                <FaClock style={{ color: "#e91e63" }} />
                <label htmlFor="oldest">Oldest</label>
                <input
                  type="radio"
                  id="oldest"
                  name="sort"
                  value="oldest"
                  checked={selectedSort === "oldest"}
                  onChange={handleSortChange}
                />
              </div>

              <div className="sort-option">
                <FaTag style={{ color: "#e91e63" }} />
                <label htmlFor="price-low-high">Price (low to high)</label>
                <input
                  type="radio"
                  id="price-low-high"
                  name="sort"
                  value="price-low-high"
                  checked={selectedSort === "price-low-high"}
                  onChange={handleSortChange}
                />
              </div>

              <div className="sort-option">
                <FaTag style={{ color: "#e91e63" }} />
                <label htmlFor="price-high-low">Price (high to low)</label>
                <input
                  type="radio"
                  id="price-high-low"
                  name="sort"
                  value="price-high-low"
                  checked={selectedSort === "price-high-low"}
                  onChange={handleSortChange}
                />
              </div>

              <div className="sort-option">
                <FaRulerCombined style={{ color: "#e91e63" }} />
                <label htmlFor="area-low-high">Area (low to high)</label>
                <input
                  type="radio"
                  id="area-low-high"
                  name="sort"
                  value="area-low-high"
                  checked={selectedSort === "area-low-high"}
                  onChange={handleSortChange}
                />
              </div>

              <div className="sort-option">
                <FaRulerCombined style={{ color: "#e91e63" }} />
                <label htmlFor="area-high-low">Area (high to low)</label>
                <input
                  type="radio"
                  id="area-high-low"
                  name="sort"
                  value="area-high-low"
                  checked={selectedSort === "area-high-low"}
                  onChange={handleSortChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="customPageWidth section-margin">
        <div class="featured-properties-app">
          <h2>Looking To</h2>
        </div>
        <div className="filterToggles">
          <button className="toggle">
            <span style={{ fontSize: "12px" }}>Buy</span>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </button>
          <button className="toggle">
            <span style={{ fontSize: "12px" }}>Rent</span>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </button>
          <button className="toggle">
            <span style={{ fontSize: "11px" }}>Vacational Rental</span>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </button>
        </div>
      </div>

      <>
        <div className="featured-properties customPageWidth section-margin">
          <div className="featured-properties-app">
            <h2>Featured Properties</h2>
            <div className="see-all">See All &gt;</div>
          </div>
          <div className="cards main-carousel">
            <Flickity
              className={"property-items carousel"}
              elementType={"div"}
              options={flickityOptions}
              reloadOnUpdate={true}
            >
              {random.length > 0 ? (
                random.map((property, id) => (
                  <div className="card" data-index={id} key={property.id}>
                    <div className="card-mainImage">
                      <img
                        src={
                          property.uploads?.length > 0
                            ? property.uploads[0].media
                            : "https://via.placeholder.com/400x200"
                        }
                        alt={property.property_title}
                      />
                    </div>
                    <div className="card-content">
                      <p className="card-heading">{property.property_title}</p>
                      <div className="tags">
                        <span className="tag">{property.property_type}</span>

                        <div className="tag">{property.status}</div>
                      </div>
                      <div
                        className="location card-info"
                        style={{ marginTop: 6 }}
                      >
                        {property.address?.length > 0 ? (
                          <>
                            <FaMapMarkerAlt style={{ color: "#ff3054" }} />
                            <span>{property.address[0].city}</span>
                            <span>{property.address[0].state}</span>
                            <span>{property.address[0].country}</span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="more-info card-info">
                        <div className="descriptive">
                          {property.details?.length > 0 ? (
                            <>
                              <span>
                                <FaBed style={{ color: "#ff3054" }} />
                                {property.details[0].bedrooms}
                              </span>

                              <FaBath style={{ color: "#ff3054" }} />
                              <span>{property.details[0].bathrooms}</span>
                              <span>
                                <FaRulerCombined style={{ color: "#ff3054" }} />
                                {property.details[0].size}
                                {property.details[0].size_prefix || "sq ft"}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* <div className="info" style={{ display: "flex", columnGap: 6 }}>
                                            <FaRulerCombined style={{ color: "red" }} />
                                            <p>1900 Sq Ft</p>
                                        </div> */}
                      </div>
                      <div className="card-price"></div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No properties available.</p>
              )}
            </Flickity>
          </div>
        </div>
      </>
      {/* <Flickity className={'property-items carousel'} elementType={'div'} options={flickityOptions} reloadOnUpdate={true}> */}

      <div className="search-by-agent customPageWidth section-margin">
        <div class="featured-properties-app">
          <h2>Search By Agent</h2>
          <div class="see-all">See All &gt;</div>
        </div>
        <>
          <div className="cards main-carousel">
            <Flickity
              className={"property-items carousel"}
              elementType={"div"}
              options={flickityOptions}
              reloadOnUpdate={true}
            >
              {random.length > 0 ? (
                random.map((property, id) => (
                  <div className="card" data-index={id} key={property.id}>
                    <div className="card-mainImage">
                      <img
                        src={
                          property.uploads?.length > 0
                            ? property.uploads[0].media
                            : "https://via.placeholder.com/400x200"
                        }
                        alt={property.property_title}
                      />
                    </div>
                    <div className="card-content">
                      <p className="card-heading">{property.property_title}</p>
                      {/* You can add more property details here */}
                    </div>
                  </div>
                ))
              ) : (
                <p>No properties available.</p>
              )}
            </Flickity>
          </div>
        </>
      </div>


      {/* </Flickity> */}
      <div className="property-results customPageWidth section-margin">
        <div class="featured-properties-app">
          <h2 style={{ marginBottom: "12px" }}>Property Results</h2>
        </div>
        <div className="property-items">
          {random.length > 0 ? (
            random.map((property, id) => (
              <div className="item" data-index={id} key={property.id}>
                <Link
                  to={`/detailproperty/${property._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="property-image">
                    <img
                      src={
                        property.uploads?.length > 0
                          ? property.uploads[0].media
                          : "https://via.placeholder.com/400x200"
                      }
                      alt={property.property_title}
                    />
                  </div>
                  <div className="card-content">
                    <p className="card-heading">{property.property_title}</p>
                  </div>
                  <div className="property-info">
                    <p className="price">
                      {" "}
                      {property.after_price}
                      {property.sale_rent_price}
                    </p>
                    <div className="descriptive">
                      {property.details?.length > 0 ? (
                        <>
                          <span>
                            <FaBed />
                            {property.details[0].bedrooms}
                          </span>

                          <FaBath />
                          <span>{property.details[0].bathrooms}</span>
                          <span>
                            <FaRulerCombined />
                            {property.details[0].size_prefix}
                            {property.details[0].size_prefix || "sq ft"}
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <p className="address">
                      {property.address?.length > 0 ? (
                        <>
                          <FaMapMarkerAlt />
                          <span>{property.address[0].city}</span>
                          <span>{property.address[0].state}</span>
                          <span>{property.address[0].country}</span>
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <p>No properties available.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default FeatureProperty;
