import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import PropertyResults from "./pages/PropertyResults";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import SignUp from "./pages/Signup";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";
import AddProperty from "./pages/AddProperty";
import Feature from "./pages/Feature";
import Map from "./pages/Map";
import Virtual from "./pages/Virtual";
import SubListing from "./pages/SubListing";
import FloorPlan from "./pages/FloorPlan";
import PrivateNote from "./pages/PrivateNote";
import Agreement from "./pages/Agreement";
import Upload from "./pages/Upload";
import Details from "./pages/Details";
import FeatureProperty from "./pages/FeatureProperty";
import DetailProperty from "./pages/DetailProperty";
import UserAcount from "./pages/UserAcount";
import Popup from "./pages/Popup";
import AgentPage from "./pages/AgentPage";
import LawyerPage from "./pages/LawyerPage";
import LoanPage from "./pages/LoanPage";
import FavouritePage from "./pages/FavouritePage";
import ProfilePage from "./pages/ProfilePage";
import SortBy from "./pages/SortBy";
import PropertyResult from "./pages/PropertyResult";
import FavouriteLogin from "./pages/FavouriteLogin";
import Schedule from "./pages/Schedule";
import FeatureProperties from "./pages/FeatureProperties";
import SearchByAgent from "./pages/SearchByAgent";
import EditProfile from "./pages/EditProfile";
// import FlickityTest from "./pages/FlickityTest";
import Leave_A_Review from "./pages/Leave_A_Review";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />

        <PropertyResults />
        <div className="footerUp">
          {/* COMMENT */}
          <Routes>
            <Route path="/review" element={<Leave_A_Review />} />
            <Route path="/Profile/Edit" element={<EditProfile />} />
            <Route path="/SearchByAgent" element={<SearchByAgent />} />
            <Route path="/Feature" element={<FeatureProperties />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/Favourite" element={<FavouriteLogin />} />
            <Route path="/search" element={<PropertyResult />} />
            <Route path="/sort" element={<SortBy />} />
            <Route path="/profilepage/:id" element={<ProfilePage />} />
            <Route path="/favourites" element={<FavouritePage />} />
            <Route path="/Loans" element={<LoanPage />} />
            <Route path="/lawyers" element={<LawyerPage />} />
            <Route path="/agents" element={<AgentPage />} />
            <Route path="/detailproperty/:id" element={<DetailProperty />} />
            <Route path="/useracount" element={<UserAcount />} />
            <Route path="/popup" element={<Popup />} />
            <Route path="/" element={<FeatureProperty />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/detail" element={<Details />} />
            <Route path="/floorplan" element={<FloorPlan />} />
            <Route path="/privatenote" element={<PrivateNote />} />
            <Route path="/agreement" element={<Agreement />} />
            <Route path="/virtual" element={<Virtual />} />
            <Route path="/sublisting" element={<SubListing />} />
            <Route path="/Features" element={<Feature />} />
            <Route path="/map" element={<Map />} />
            <Route path="/maps" element={<Map />} />
            <Route path="/propertyresults" element={<PropertyResults />} />
            <Route path="/addproperty" element={<AddProperty />} />
            <Route path="/properties" element={<AddProperty />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
