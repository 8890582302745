import React, { useState, useEffect } from "react";
// import Flickity from "react-flickity-component";
// import { FaMapMarkerAlt, FaBed, FaBath, FaRulerCombined } from "react-icons/fa";
import "flickity/css/flickity.css";
const PropertyResults = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Cookie", localStorage.getItem("bearer-token"));
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("bearer-token"),
          },
        };

        const response = await fetch(
          `${process.env.REACT_APP_backend_server_url}/properties`,
          requestOptions
        );
        const result = await response.json();

        setProperties(result["properties"]);
        setLoading(false);
      } catch (error) {
        setProperties([]);
        console.error(error);
        setLoading(false);
      }
    };
    fetchProperties();
  }, []);
  if (loading) {
    return <p>Loading properties...</p>;
  }
  const flickityOptions = {
    initialIndex: 0,
    cellAlign: "left",
    contain: true,
    pageDots: true,
    wrapAround: true,
  };
  return (
    <>
      {/* <main>
      <div className="property-results customPageWIdth section-margin">
        <div className="section-heaing-custom">
        </div>
        <Flickity
          className={"property-items carousel"}
          elementType={"div"}
          options={flickityOptions}
          reloadOnUpdate={true}
        >
          {properties.length > 0
            ? properties.map((property, index) => (
                <div
                  className="property-item"
                  key={index}
                  style={{
                    width: "50%",
                    boxSizing: "border-box",
                    padding: "10px",
                  }}
                >
                  <div className="property-image">
                    <img
                      src={
                        property.uploads.length > 0? 
                        property.uploads[0].media :
                        "https://via.placeholder.com/400x200"
                      }
                      alt={property.property_title}
                    />
                  </div>
                  <div className="property-info">
                    <p className="price">
                    
                    {property.after_price}
                      {property.sale_rent_price}
                      {property.second_price}K
                    </p>
                    <div className="descriptive">
                    { property.details.length > 0 ?
                      
                      <>
                      <span>
                        <FaBed />
                        {property.details[0].bedrooms }
                      </span>
                     
                        <FaBath />
                        <span>
                        {property.details[0].bathrooms}
                      </span>
                      <span>
                        <FaRulerCombined />
                        {property.details[0].size}
                        {property.details[0].size_prefix ||
                          "sq ft"}
                      </span>
                      </>
                      
                      : ""}
                 
                    </div>
                    <p className="address">
                      { property.address.length > 0 ?
                      
                      <><FaMapMarkerAlt />
                      <span>{property.address[0].city}</span> 
                      <span>{property.address[0].state}</span>
                      <span>{property.address[0].country}</span>
                      </>
                      
                      : ""}
                      
                     
                    </p>
                  </div>
                </div>
              ))
            : ""}
        </Flickity>
      </div>
    </main> */}
    </>
  );
};
export default PropertyResults;
