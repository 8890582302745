import React, { useRef, useEffect, useState } from 'react';
import { FiUser , FiSettings } from "react-icons/fi"; 
import { FaSignInAlt } from "react-icons/fa"; 
import { Link } from "react-router-dom";
import { render } from '@testing-library/react';

const UserProfile = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  useEffect(() => {
    const fetchProfileData = async () => {
      const userId = JSON.parse(localStorage.getItem("user"))._id;
      const requestOptions = {
          method: "GET",
          headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("bearer-token"),
          },
      };
      try {
      
          const response = await fetch(
              `${process.env.REACT_APP_backend_server_url}/addimage/${userId}`,
              requestOptions
          );
        
     
          if (response.ok && response.status == 200) {
            const result = await response.json();
          
         
            console.log("Profile data:", result);
          }
          
          else{
              console.log(
                "Profile Not Found"
              )
          }
      } catch (error) {
      
          console.error("Fetch error:", error);
      }
  };
  
  fetchProfileData();
}, []);

const handleIconClick = () => {
    fileInputRef.current.click();
};

const handleFileChange = async (event) => {
    const file = event.target.files[0];
    debugger;
    if (file) {
        const formDataObj = new FormData();
     
        formDataObj.append('profile_image',file);
        formDataObj.append("property_id", localStorage.getItem("properties"));
        formDataObj.append("user_id", JSON.parse(localStorage.getItem("user"))._id);
      
        try {
            const requestOptions = {
                method: 'POST',
                body: formDataObj,
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('bearer-token'),
                },
            };
debugger;
            const response = await fetch(
                `${process.env.REACT_APP_backend_server_url}/addimage/add`,
                requestOptions
            );

            if (response.ok) {
                const data = await response.json();

                if (data.profileImage) {
                    localStorage.setItem('profile_image', data.profileImage);
                    setImagePreview(data.profileImage);
                }
            } else {
                const errorText = await response.text();
                console.error('Failed to upload image:', errorText);
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    }
};


  // const handleIconClick = () => {
  //     fileInputRef.current.click();
  // };

  // const handleFileChange = (event) => {
  //     const file = event.target.files[0];
  //     if (file) {
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //             setImagePreview(reader.result);
          
  //         };
  //         reader.readAsDataURL(file);
  //     }
  // };

  const handleLoginLogout = () => {
      setIsLoggedIn(prevState => !prevState);
  };

  const handleLogout = async () => {
      try {
          const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({}),
          };

          const response = await fetch(
              `${process.env.REACT_APP_backend_server_url}/auth/logout`,
              requestOptions
          );

          if (response.ok) {
              const result = await response.json();
              console.log('Logout successful', result);

              localStorage.removeItem('bearer-token');
              localStorage.removeItem('user');
              setSuccess('Logout successful!');
              setError(null);
              setIsLoggedIn(false);
          } else {
              const result = await response.text();
              setError(result);
              setSuccess(null);
          }
      } catch (error) {
          setError('An error occurred. Please try again later.');
          console.error(error);
      }
  };


  return (
    <main>
      <div className="user-profile customPageWIdth section-margin">
        <div className="before-login login-width">
          <div className="iconbtn-wrapper">
            {/* Profile Icon */}
            <div>
            <div className="profile-icon" onClick={handleIconClick} style={{ cursor: 'pointer' }}>
                {imagePreview ? (
                    <img
                        src={imagePreview}
                        alt="Profile Preview"
                        style={{ width: '120px', height: '120px', borderRadius: '50%' }} 
                    />
                ) : (
                    <FiUser  size={24} color="#292D32" />
                )}
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }} 
                    accept="image/*" 
                />
            </div>
        </div>


            <Link to="/Profile/Edit" style={{ textDecoration: "none" }}>
              {
                localStorage.user ? <button className="to-login">{JSON.parse(localStorage.user).username} Profile</button> : <button className="to-login">Login to your account</button>
              }
            </Link>
          </div>
          <div className="settinglogin-wrapper">
            {/* Settings Icon */}
            <div className="settings child">
              <div className="icon">
                <FiSettings size={24} color="#292D32" />
              </div>
              <span>Settings</span>
            </div>

            {/* Login Icon */}
            <div className="login child">
              <Link to="/login">
                <div className="icon">
                  <FaSignInAlt size={24} color="#292D32" />
                </div>
              </Link>
              <div>
                {localStorage.user ? (
                  <div>
                    {/* <span style={{ marginRight: '10px' }}>Welcome, User!</span> */}
                    <span onClick={handleLogout} style={{ cursor: 'pointer' }}>
                      Logout
                    </span>
                  </div>
                ) : (
        <Link to="/login" style={{ textDecoration: "none", color: "#333" }}>
          <span onClick={handleLoginLogout} style={{ cursor: 'pointer' }}>Login</span>
        </Link>
      )}
    </div>
            </div>
          </div>

          {/* Footer Text */}
          {/* <div className="yidV-text">
            <p>YidEstate</p>
            <p>Version 0.9.1 beta</p>
          </div> */}
        </div>
      </div>
    </main>
  );
};

export default UserProfile;
