import React, { useState } from 'react';
import Select from 'react-select';
import { Button } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";

const Details = () => {
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
    const [formData, setFormData] = useState({
        bedrooms:'',
        bathrooms:'',
        area_size:'',
        size_postfix:'',
        land_area:'',
        land_area_postfix:'',
        garages:'',
        garage_size:'',
        year_built:'',
        place_name:'',
        availability:'',
        security_feature: [],
        renovated:'',
        amenities:'',
        luxurious_feature: [],
        title:'',
        value:'',
        user_id:''
    
      });
      const [success, setSuccess] = useState(null);
      const [inputFields, setInputFields] = useState([{ title: '', value: '' }]);

      const handleAddInputField = (event) => {
        setInputFields([...inputFields, { title: '', value: '' }]);
     
      };
      
      const handleInputChange = (index, event) => {
        const updatedInputFields = [...inputFields];
        updatedInputFields[index][event.target.name] = event.target.value;
        setFormData({ ...formData, [event.target.name]: event.target.value });
        setInputFields(updatedInputFields);
      };
      
      const handleRemoveInputField = (index) => {
        const updatedInputFields = [...inputFields];
        updatedInputFields.splice(index, 1);
        setInputFields(updatedInputFields);
      };
  
      const [isAvailableYes, setIsAvailableYes] = useState(false);
      const [isAvailableNo, setIsAvailableNo] = useState(false);
    
      const handleYesCheckboxChange = (event) => {
        setIsAvailableYes(event.target.checked);
        setIsAvailableNo(false);
      };
    
      const handleNoCheckboxChange = (event) => {
        setIsAvailableNo(event.target.checked);
        setIsAvailableYes(false);
      };
      const handleChange = (event) => {
        if (event.target.type === 'file') {
          setFormData({ ...formData, [event.target.name]: event.target.files[0] });
        } else {
          setFormData({ ...formData, [event.target.name]: event.target.value });
        }
      };
      const handleClick = () => {
        console.log('Button clicked!');
      };


      const validateForm = () => {
        const errors = {};
        if (!formData.bedrooms) {
          errors.bedrooms = "Bedrooms is required";
        }
        if (!formData.bathrooms) {
          errors.bathrooms = "bathrooms is required";
        }
        if (!formData.place_name) {
          errors.place_name = "place_name is required";
        }
        if (!formData.security_feature) {
          errors.security_feature = "security_feature is required";
        }
        if (!formData.size_postfix) {
          errors.size_postfix = "size_postfix is required";
        }
        // Add additional validations as needed
    
        return errors;
      };

      const handleNext = async (event) => {
        event.preventDefault();
      
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
          setFormErrors(errors);
          return;
        }
   
        const formDataObj = new FormData();
        formDataObj.append('bedrooms', formData.bedrooms || '');
        formDataObj.append('bathrooms', formData.bathrooms || '');
        formDataObj.append('area_size', formData.area_size || '');
        formDataObj.append('size_postfix', formData.size_postfix || '');
        formDataObj.append('land_area', formData.land_area || '');
        formDataObj.append('land_area_postfix', formData.land_area_postfix || '');
        formDataObj.append('garages', formData.garages || '');
        formDataObj.append('garage_size', formData.garage_size || '');
        formDataObj.append('year_built', formData.year_built || '');
        formDataObj.append('place_name', formData.place_name || '');
        formDataObj.append('availability', formData.availability || '');
        formDataObj.append('security_feature', formData.security_feature || '');
        formDataObj.append('renovated', formData.renovated ? 'true' : 'false'); 
        formDataObj.append('amenities', formData.amenities || '');
        formDataObj.append('luxurious_feature',formData.luxurious_feature)
        formDataObj.append('title', formData.title || '');
        formDataObj.append('value', parseFloat(formData.value) || 0); 
       
         const requestOptions = {
          method: "POST",
          body: JSON.stringify({
            property_id: localStorage.properties,
            bedrooms: formData.bedrooms || '',
            bathrooms: formData.bathrooms || '',
            area_size: formData.area_size || '',
            size_postfix: formData.size_postfix || '',
            land_area: formData.land_area || '',
            land_area_postfix: formData.land_area_postfix || '',
            garages: formData.garages || '',
            garage_size: formData.garage_size || '',
            year_built: formData.year_built || '',
            place_name: formData.place_name || '',
            availability: formData.availability || 'Available', // Default value
            security_feature: JSON.stringify(formData.security_feature || []), // Convert to string
            renovated: formData.renovated ? true : false,
            amenities: formData.amenities || 'Basic', // Default value
            luxurious_feature: JSON.stringify(formData.luxurious_feature || []), // Convert to string
            title: formData.title || '',
            value: parseFloat(formData.value) || 0,
            user_id: JSON.parse(localStorage.user)._id
          }),
          headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + localStorage.getItem("bearer-token")
          }
        };
    
        try {
         
          // const backendUrl = process.env.REACT_APP_BACKEND_URL;
          // const response = await fetch(`${backendUrl}/details`, requestOptions);
          // const response = await fetch(
          //   "https://25fd-139-135-43-100.ngrok-free.app/details",
          //   requestOptions
          // );
          const response = await fetch(
            `${process.env.REACT_APP_backend_server_url}/details`,
            requestOptions
          );
      
          if (response.ok) {
            const result = await response.json();
            console.log('Detail added successfully', result.message);
            setSuccess(result.message);
            navigate("/upload");
            setFormData({
              bedrooms: '',
              bathrooms: '',
              area_size: '',
              size_postfix: '',
              land_area: '',
              land_area_postfix: '',
              garages: '',
              garage_size: '',
              year_built: '',
              place_name: '',
              availability: '',
              security_feature: [],
              renovated: '',
              amenities: '',
              luxurious_feature: [],
              title: '',
              value: '',
              user_id: ''
            });
          } else {
            const result = await response.text();
            console.error('Error adding detail', result);
            setSuccess(null);
          }
        }catch (error) {
          console.error('Request failed', error);
        }
      };
      
  return (
   <>
   <form>
 
   <div className='add-property'>
      <div className="form-wrapper" style={{boxShadow:'0px 1px 3px -1px',marginTop:'12px', borderRadius:'9px'}}>
        <span className='form-span'>Details</span>

      <div>
      <div className="form-control">
        <label className='form-label'>Bedrooms:</label>
        <input className='form-input'  type="number" name="bedrooms" placeholder="Enter your the bedrooms"  value={formData.bedrooms} onChange={handleChange} />
        {formErrors.bedrooms && (
              <span className="error-app" >{formErrors.bedrooms}</span>
            )}
      </div>
      </div>
      <div>
      <div className="form-control">
        <label className='form-label'>Bathrooms:</label>
        <input className='form-input'  type="number" name="bathrooms" placeholder="Enter your the bathrooms"  value={formData.bathrooms} onChange={handleChange} />
        {formErrors.bathrooms && (
              <span className="error-app" >{formErrors.bathrooms}</span>
            )}
      </div>
      </div>
      <div className="form-control">
        <label className='form-label'>Area Size:</label>

        <input  className='form-input' type="text" name="area_size" placeholder="Enter  the area size"  value={formData.area_size} onChange={handleChange} />
      </div>
      <div className="form-control">
        <label className='form-label'>Size postfix:</label>
        <input className='form-input' type="text" name="size_postfix" placeholder="Enter  the size-postfix"  value={formData.size_postfix} onChange={handleChange} />
      </div >
      <div className="form-control">
        <label className='form-label'>Land Area:</label>
        <input className='form-input' type="text" name="land_area" placeholder="Enter  the land area"  value={formData.land_area} onChange={handleChange} />
       
      </div>
      <div className="form-control">
        <label className='form-label'>Land Area Size postfix:</label>
        <input className='form-input' type="text" name="land_area_postfix" placeholder="Enter  the land_area_postfix"  value={formData.land_area_postfix} onChange={handleChange} />
      </div>
      <div className="form-control">
        <label className='form-label'>Garages:</label>
        <input className='form-input' type="text" name="garages" placeholder="Enter your the garages"  value={formData.garages} onChange={handleChange} />
      </div>
      <div className="form-control">
        <label className='form-label'>Garage size:</label>
        <input className='form-input' type="text" name="garage_size" placeholder="Enter the garage size"  value={formData.garage_size} onChange={handleChange} />
      </div>
      <div className="form-control">
        <label className='form-label'>year built:</label>
        <input className='form-input' type="text" name="year_built" placeholder="Enter the year built"  value={formData.year_built} onChange={handleChange} />
      </div>
      <div className="form-control">
        <label className='form-label'>Place Name:</label>
        <input className='form-input' type="text" name="place_name"  placeholder="Enter place name"  value={formData.place_name} onChange={handleChange} />
        {formErrors.place_name && (
              <span className="error-app" >{formErrors.place_name}</span>
            )}
      </div>
      <div>
      <div className='from-handle'>
      <div className="form-control">
      <label className='form-label'>availability:</label>
<div className='from-check'>
      <input
      className='form-input'
        type="checkbox"
        id="availability-yes-checkbox"
        value={formData.availability}
        checked={isAvailableYes}
        onChange={handleYesCheckboxChange}
      />
     
      <label className='form-yes' htmlFor="availability-yes-checkbox">Yes</label>
      </div>
      <div className='from-check'>
      <input
      className='form-input'
        type="checkbox"
        id="availability-no-checkbox"
        checked={isAvailableNo}
        onChange={handleNoCheckboxChange}
      />
      <label  className='form-yes' htmlFor="availability-no-checkbox">No</label>
      </div>
      </div>
    </div>
    </div>
    <div className="form-control">
            <label className="form-label">security_feature:</label>
            <select
              className="form-input"
              name="security_feature"
              required
              value={formData.security_feature}
              onChange={handleChange}
            >
              <option value="Security Camera">Security Camera</option>
              <option value="Electric Fence">Electric Fence</option>
              <option value="Wooden Fence">Wooden Fence'</option>
              <option value="Security Guard">Security Guard</option>       
            </select>
            {formErrors.security_feature && (
              <span className="error">{formErrors.security_feature}</span>
            )}
          </div>
    {/* <div className='form-option'>
    <div className="form-control">
  <label className='form-label'>Security Feature:</label>
  <div  className='form-select'>
    <Select
      isMulti
      options={showFor}
      value={formData.security_feature}
      onChange={(optiontType) => {
        setFormData({ ...formData, security_feature: optiontType });
      }}
      formatOptionLabel={(option) => (
        <div>
          <span>{option.label}</span>
        </div>
      )}
    />
       {formErrors.security_feature && (
              <span className="error-app" >{formErrors.security_feature}</span>
            )}
  </div>
</div>
</div> */}
<div>
      <div className="form-control">
        <label className='form-label'>Bedrooms:</label>
        <input className='form-input'  type="number" name="bedrooms" placeholder="Enter your the bedrooms"  value={formData.bedrooms} onChange={handleChange} />
      </div>
      </div>
      <div>
      <div className="form-control">
        <label className='form-label'>Bathrooms:</label>
        <input className='form-input'  type="number" name="bathrooms" placeholder="Enter your the bathrooms"  value={formData.bathrooms} onChange={handleChange} />
      </div>
      </div>
      <div className="form-control">
        <label className='form-label'>Area Size:</label>

        <input  className='form-input' type="text" name="area_size" placeholder="Enter  the area size"  value={formData.area_size} onChange={handleChange} />
      </div>
      <div className="form-control">
        <label className='form-label'>Size postfix:</label>
        <input className='form-input' type="text" name="size_postfix" placeholder="Enter  the size-postfix"  value={formData.size_postfix} onChange={handleChange} />
        {formErrors.size_postfix && (
              <span className="error-app" >{formErrors.size_postfix}</span>
            )}
        {formErrors.property_title && (
              <span className="error">{formErrors.property_title}</span>
            )}
      </div >
      <div className="form-control">
            <label className="form-label">Renovated:</label>
            <select
              className="form-input"
              name="renovated"
              required
              value={formData.renovated}
              onChange={handleChange}
            >
              <option value=" Yes">Yes</option>
              <option value=" No"> No</option>
              <option value=" No Idea"> No Idea</option>
                
            </select>
            {formErrors.renovated && (
              <span className="error">{formErrors.renovated}</span>
            )}
          </div>
      {/* <div className='form-option'>
      <div className="form-control">
  <label className='form-label'>Renovated:</label>
  <div  className='form-select'>
    <Select
      isMulti
      options={showType}
      value={formData.renovated}
      onChange={(optionValue) => {
        setFormData({ ...formData, renovated: optionValue });
      }}
      formatOptionLabel={(option) => (
        <div>
          <span>{option.label}</span>
        </div>
      )}
    />
  </div>
</div>
</div> */}
 <div className="form-control">
            <label className="form-label">amenities:</label>
            <select
              className="form-input"
              name="amenities"
              required
              value={formData.amenities}
              onChange={handleChange}
            >
              <option value="Spanish Tiles">Spanish Tiles</option>
              <option value="Marble Flooring"> Marble Flooring</option>
              <option value="pool">  pool</option>
              <option value=" Home Theater">  Home Theater</option>
              <option value="Spa">  Spa</option>
          
            </select>
            {formErrors.amenities && (
              <span className="error">{formErrors.amenities}</span>
            )}
          </div>
{/* <div className='form-option'>
<div className="form-control">
  <label className='form-label'>amenities:</label>
  <div  className='form-select'>
    <Select
      isMulti
      options={typeCast}
      value={formData.amenities}
      onChange={(optionCast) => {
        setFormData({ ...formData, amenities: optionCast });
      }}
      formatOptionLabel={(option) => (
        <div>
          <span>{option.label}</span>
        </div>
      )}
    />
  </div>
</div>
</div> */}
 <div className="form-control">
            <label className="form-label">luxurious_feature:</label>
            <select
              className="form-input"
              name="luxurious_feature"
              required
              value={formData.luxurious_feature}
              onChange={handleChange}
            >
              <option value="Clubhouse ">Clubhouse </option>
              <option value=" Swimmin Pool"> Swimmin Pool </option>          
            </select>
            {formErrors.luxurious_feature && (
              <span className="error">{formErrors.luxurious_feature}</span>
            )}
          </div>
{/* <div className='form-option'>
<div className="form-control">
  <label className='form-label'>Luxurious Features:</label>
  <div  className='form-select'>
  <Select
      isMulti
      options={options}
      value={formData.luxurious_feature}
      onChange={(selectedOptions) => {
        setFormData({ ...formData, luxurious_feature: selectedOptions });
      }}
      formatOptionLabel={(option) => (
        <div>
          <input
            type="checkbox"
            checked={Array.isArray(formData.luxurious_feature) && formData.luxurious_feature.includes(option.value)}
            onChange={() => {
              const newSelectedOptions = [...formData.luxurious_feature];
              if (newSelectedOptions.includes(option.value)) {
                newSelectedOptions.splice(newSelectedOptions.indexOf(option.value), 1);
              } else {
                newSelectedOptions.push(option.value);
              }
              setFormData({ ...formData, luxurious_feature: newSelectedOptions });
            }}
          />
          <span>{option.label}</span>
        </div>
      )}
    />
  </div>
</div>
</div> */}


    </div>
    <div className="form-wrapper" style={{boxShadow:'0px 1px 3px -1px',marginTop:'12px', borderRadius:'9px'}}>
    <div  className="form-control">
    {inputFields.map((inputField, index) => (
      <div className='form-event' key={index}>
        <div>
          <label className='form-label'>Title:</label>

        <input
        className='form-title'
          type="text"
          name="title"
          value={inputField.title}
          onChange={(event) => handleInputChange(index, event)}
          placeholder="Title"
        />
        </div>
        <div>
          <label className='form-label'>Value:</label>
          
        <input
        className='form-title'
          type="text"
          name="value"
          value={inputField.value}
          onChange={(event) => handleInputChange(index, event)}
          placeholder="Value"
        />
        </div>
        <button className='form-crose' onClick={() => handleRemoveInputField(index)}>×</button> 
      </div>
    ))}
    <button className='add_new' onClick={handleAddInputField}>Add New</button>
  </div>
  </div> 
  <div className="form-wrapper" style={{boxShadow:'0px 1px 3px -1px',marginTop:'12px', borderRadius:'9px'}}>
      <div style={{display: 'flex',
    justifyContent: 'space-between'}}>
<div  className="form-containe">
      <Button
      className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleClick}
      >
        Cancel
      </Button>
    </div>
    <div className='form-contain'>
      <Button
       className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleNext}
      >
       Next
      </Button>
    </div>
</div>

      </div>
      </div>
   </form>
   </>
  )
}

export default Details