import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { LiaMapMarkerAltSolid } from "react-icons/lia";
import { FaRegStar } from "react-icons/fa6";
import { GoTag } from "react-icons/go";
import { FaArrowLeft } from "react-icons/fa";
import { CiHeart } from "react-icons/ci";
import { FaShareAlt } from "react-icons/fa";
import { FaPrint } from "react-icons/fa";
import { FaFlag } from "react-icons/fa";
import { FaRulerCombined } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { FaSnowflake } from "react-icons/fa";
import { FaTv } from "react-icons/fa";
import { FaWifi } from "react-icons/fa";
import { LuRefrigerator } from "react-icons/lu";
import { FaWindowMaximize } from "react-icons/fa";
import { IoIosStar } from "react-icons/io";
import { IoChevronForward } from "react-icons/io5";
import { FaRegEnvelope } from "react-icons/fa";
import { LuPhone } from "react-icons/lu";
import { IoBedOutline } from "react-icons/io5";
import { FaBath } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";

const DetailProperty = () => {
  const { id } = useParams(); 
  const [property, setProperty] = useState(null); 
  const fetchPropertyDetails = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        'ngrok-skip-browser-warning': 'any',
        authorization: "Bearer " + localStorage.getItem("bearer-token"),
      },
    };

    const response = await fetch(
      `${process.env.REACT_APP_backend_server_url}/properties/${id}`, 
      requestOptions
    );

    if (response.ok) {
      const result = await response.json();
    
      setProperty(result); 
      console.log("Property fetched successfully", result);
    } else {
      const result = await response.json();
      console.error("Error fetching property details", result);
    }
  };

  useEffect(() => {
    fetchPropertyDetails();
  }, [id]);

  if (!property) {
    return <p>Loading...</p>; 
  }
  const whatsappNumber = property.userprofiles?.length > 0 ? property.userprofiles[0].whatsapp_number : "";

  const email = property.user_id?.email;

  return (
    <>
    <div className="container-apps">
    <div className="image-container">
    <img
                      src={
                        property.uploads?.length > 0? 
                        property.uploads[0].media :
                        "https://via.placeholder.com/400x200"
                      }
                      alt={property.property_title}
                    />
       
      <div className="buttons">
    <div className="button">
    <FaArrowLeft />
    
    </div>
    <di className="icon-app">
    <div className="button">
    <CiHeart />
    </div>
    <div className="button">
    <FaShareAlt />
    </div>
    <div className="button">
    <FaPrint />
    </div>
    <div className="button">
    <FaFlag />
    </div>
    </di>
   </div>
    </div>
    
    <div className="property-details">
    <h1 className="card-heading">{property.property_title}</h1>
    <div className="tags">
        <div className="tag">{property.label
                                        }</div>
        <div className="tag">{property.status
                                        }</div>
       
      </div>
      <div className="price">
           
      ${property.after_price}
     {/* {property.sale_rent_price}
      {property.second_price} */}
      </div>
      <div className="info">
        <div className="info-item">

        <FaRulerCombined className="i-cloud" />
  <span>
    {property.details?.length > 0 ? (
      property.details[0]?.area_size?.$numberDecimal 
        ? property.details[0].area_size.$numberDecimal || "sq ft"
        : "N/A"
    ) : (
      "" 
    )} sq ft </span>
 
        </div>
        <div className="info-item">
       
        
        <FaCalendarAlt className="i-cloud" />
  <span>
    {property.details?.length > 0 ? (
      property.details[0]?.year_built 
        ? property.details[0].year_built || "Year built"
        : "Year built not available"
    ) : (
      ""
    )} Year built</span>
  
        </div>
      </div>
    </div>
    <div className="details">
     
      <div className="header-plans">
          <div className="title">Details</div>
         
          <a className="link" href="#">More Details </a>
        </div>
      <table className="details-table">
        <tbody>
          <tr>
            <th>Created Date :</th>
            <td>{property.createdAt}</td>
          </tr>
          <tr>
            <th>Last Updated :</th>
            <td>{property.updatedAt}</td>
          </tr>
          <tr>
            <th>Property ID :</th>
            <td>{property._id}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="features">
     
      <div className="header-plans">
          <div className="title">Features</div>
          {/* <a href="#"></a> */}
          <a className="link" href="#">Show More </a>
        </div>
      <div className="features-list">
        <div className="feature-item">
          <div className="feature-item-app">
        <FaSnowflake style={{width:"23px"}}/>

        </div>
        <span>
        {property.features?.length > 0 ?
<>
    <span>{property.features[0].Air_Conditioning}</span>
</>
: ""}
  </span>

        </div>
        <div className="feature-item">
        <div className="feature-item-app">
         <FaTv style={{width:"23px"}}/>
        </div>
        <span>
        {property.features?.length > 0 ?
<>
    <span>{property.features[0].Tv_Cable}</span>
</>
: ""}
  </span>
        
        </div>
        <div className="feature-item">
       
        <div className="feature-item-app">
        <FaWifi style={{width:"23px"}}/>
        </div>
        <span>
        {property.features?.length > 0 ?
<>
    <span>{property.features[0].Wifi}</span>
</>
: ""}
  </span>
          
        </div>
        <div className="feature-item">
       
        <div className="feature-item-app">
        <LuRefrigerator style={{width:"23px"}}/>
        </div>
        <span>
        {property.features?.length > 0 ?
<>
    <span>{property.features[0].Refrigerator}</span>
</>
: ""}
  </span>
         
        </div>
        <div className="feature-item">
        
        <div className="feature-item-app">
        <FaWindowMaximize style={{width:"23px"}}/>
        </div>
        {property.features?.length > 0 ? (
    <span>
      {property.features[0]?.Window_Coverings 
        ? property.features[0].Window_Coverings 
        : ""} 
    </span>
  ) : (
    ""
  )}
        </div>
      </div>
    </div>
    <div className="section-title">Description</div>
    {property.floorPlans
?.length > 0 ? (
    <span>
      {property.floorPlans
[0]?.description 
        ? property.floorPlans
        [0].description 
        : ""} 
    </span>
  ) : (
    ""
  )}
     
      <div className="section-title-cards">
      <div className="section-title">Address</div>
      <a className="map-link" href="#">Open in Map &gt;</a>
      </div>
      
      <div className="address-app">
      {property.address?.length > 0 ? (
    <span>
      {property.address[0]?.address 
        ? property.address[0].address 
        : ""} 
    </span>
  ) : (
    ""
  )}
        
      </div>

      <div className="address-details">
        <div>
          <strong>City:</strong>
          <br />
          {property.address?.length > 0 ? (
    <span>
      {property.address[0]?.city 
        ? property.address[0].city 
        : ""} 
    </span>
  ) : (
    ""
  )}
        </div>
        <div>
          <strong>Area:</strong>
          <br />
          {property.address?.length > 0 ? (
    <span>
      {property.address[0]?.area 
        ? property.address[0].area 
        : ""} 
    </span>
  ) : (
    ""
  )}
         
        </div>
        
      </div>

      <div className="address-details-app">
          <strong>State:</strong>
          <br />
          {property.address?.length > 0 ? (
    <span>
      {property.address[0]?.state
 
        ? property.address[0].state
 
        : ""} 
    </span>
  ) : (
    ""
  )}
      
          
        </div>
        {property.address?.length > 0 ? (
    <span>
      {property.address[0]?.location
 
        ? property.address[0].location
 
        : ""} 
    </span>
  ) : (
    ""
  )}
      
          
    <div className="floor-plan">
    <div className="header-plans">
          <div className="title">Second Floor</div>
          {/* <a href="#"></a> */}
          <a className="link" href="#">View Floor Plan &gt;</a>
        </div>
        <div className="header-plans">
        <div className="i">
          <div>
        <IoBedOutline />
        </div>
        <div className="text-feild-plan">
        {property.sublistings?.length > 0 ? (
    <span>
      {property.sublistings[0]?.bedrooms
 
        ? property.sublistings[0].bedrooms
 
        : ""} 
     sq ft</span>
  ) : (
    ""
  )}
      
        

        </div>
        </div>
        <div className="i">
        <div>
        <FaBath />
        </div>
        <div className="text-feild-plan">
        {property.sublistings?.length > 0 ? (
    <span>
      {property.sublistings[0]?.bathrooms
 
        ? property.sublistings[0].bathrooms
 
        : ""} 
    sq ft</span>
  ) : (
    ""
  )}
    
          
        </div>

        </div>
        </div>
        <div className="header-plans">
          <div className="i">
        <div>
        
        <GoTag />
        </div>
        <div className="text-feild-plan">
        {property.sublistings?.length > 0 ? (
    <span>$
      {property.sublistings[0]?.price.$numberDecimal 
        ? property.sublistings[0].price.$numberDecimal 
        : ""}
    </span>
  ) : (
   ""
  )}  
        </div>
        </div>
        <div className="i">
        <div>
        <FaRulerCombined />
        </div>
        <div className="text-feild-plan">
                      { property.sublistings?.length > 0 ?
                      <span>{property.sublistings[0].availability } sq ft</span>        
                      : ""}   
        </div>
        </div>
        </div>
     
     
       
      </div>

      <div className="contact-info">
        <h2>Contact Information</h2>
        <div className="agency">
        <img
    src={
        property?.user_id?.length > 0 && property.user_id[0]?.addimages?.profile_image
            ? property.user_id[0].addimages.profile_image
            : "https://via.placeholder.com/50x50"
    }
    alt="Property"
    width="50"
    height="50"
/>

       
          <div>
          
            <h3>{property.property_title}</h3>
            <p>
    {property.user_id?.role ? (
        <span>{property.user_id.role}</span>
    ) : (
        <span>No role available</span> 
    )}
</p>
          </div>
          <div className="contact-icons">
          <a
        href={email ? `mailto:${email}` : '#'}
        className="email" 
        aria-label={email ? `Email ${email}` : 'No email available'}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textDecoration: 'none',
        }}
      >
        <FaRegEnvelope style={{ fontSize: '24px', cursor: 'pointer' }} /> {/* Icon only */}
      </a>
       

              <a 
            href={whatsappNumber ? `https://wa.me/${whatsappNumber}` : "#"} 
            className="phone" 
            target="_blank" 
            rel="noopener noreferrer"
        >
            {whatsappNumber && <span>{whatsappNumber}</span>}
            <LuPhone />
        </a>
          </div>
        </div>

        <ul className="options">
        <Link to="/signup" style={{ textDecoration: "none" }}>
        <li>
            <a href="#">Enquire Info</a>
            <IoChevronForward />
          </li>
          </Link>
          <Link to="/schedule" style={{ textDecoration: "none" }}>
          <li>
            <a href="#">Setup Tour</a>
            <IoChevronForward />
          </li>
          </Link>
        
          <li>
            <a href="#">Watch Video</a>
            <IoChevronForward />
          </li>
          <Link to="/virtual" style={{ textDecoration: "none" }}>
          <li>
            
            <a href="#">Virtual Tour</a>
            <IoChevronForward />
          </li>
          </Link>
        
        </ul>
      </div>

      <div className="reviews">
        <h2>Reviews</h2>
        <div className="overall-rating">
        {property.ratings && property.ratings.length > 0 ? (
  <span>
    {property.ratings.map((rating, index) => {
      const stars = [];
      if (rating.rating) {
        // Create stars with a for loop
        const maxStars = 5; // Total number of stars
        const roundedRating = Math.round(rating.rating); // Ensure the rating is an integer
        for (let i = 0; i < maxStars; i++) {
          if (i < roundedRating) {
            stars.push(<span key={i}>★</span>); // Filled star
          } else {
            stars.push(<span key={i}>☆</span>); // Empty star
          }
        }
      }

      return (
        <span key={index}>
          {stars} {/* Render stars */}
          {index < property.ratings.length - 1 && ", "} {/* Add a comma between ratings */}
        </span>
      );
    })}
  </span>
) : (
  ""
)}

        {/* <IoIosStar />
        <IoIosStar />
        <IoIosStar />
        <IoIosStar />
        <IoIosStar /> */}
        
          <span>(5 out of 5)</span>
        </div>
        <div className="review-card">
        <div className="header">
          <div className="title">
          {property.ratings?.length > 0 ? (
    <span>$
      {property.ratings[0]?.comment 
        ? property.ratings[0].comment
        : ""}
    </span>
  ) : (
   ""
  )}  
            </div>
          <div className="date">
          {property.ratings?.length > 0 ? (
    <span>
      {property.ratings[0]?.createdAt
 
        ? property.ratings[0].createdAt
 
        : ""} 
    </span>
  ) : (
    ""
  )}
    
            </div>
        </div>
        <div className="content">
          <div className="star-app">
          <div className="stars">
          {property.ratings && property.ratings.length > 0 ? (
  <span>
    {property.ratings.map((rating, index) => {
      const stars = [];
      if (rating.rating) {
        // Create stars with a for loop
        const maxStars = 5; // Total number of stars
        const roundedRating = Math.round(rating.rating); // Ensure the rating is an integer
        for (let i = 0; i < maxStars; i++) {
          if (i < roundedRating) {
            stars.push(<span key={i}>★</span>); // Filled star
          } else {
            stars.push(<span key={i}>☆</span>); // Empty star
          }
        }
      }

      return (
        <span key={index}>
          {stars} {/* Render stars */}
          {index < property.ratings.length - 1 && ", "} {/* Add a comma between ratings */}
        </span>
      );
    })}
  </span>
) : (
  ""
)}
           </div>
           <div>
           <h2 className="footer">
           {property.ratings?.length > 0 ? (
  <span>
    {property.ratings[0]?.user_id?.username
      ? property.ratings[0].user_id.username
      : "No reviews yet"}
  </span>
) : (
  <span>No ratings available</span>
)}

          </h2>
           </div>
          </div>
          <div className="text">
          {property.ratings?.length > 0 ? (
    <span>
      {property.ratings[0]?.description
        ? property.ratings[0].description
 
        : ""} 
    </span>
  ) : (
    ""
  )}
    
           </div>
        </div>
       
      </div>

      
      </div>
      {/* <div className="review-card">
        <div className="header">
          <div className="title">
          {property.ratings?.length > 0 ? (
    <span>$
      {property.ratings[0]?.comment 
        ? property.ratings[0].comment
        : ""}
    </span>
  ) : (
   ""
  )}  
            </div>
          <div className="date">
          {property.ratings?.length > 0 ? (
    <span>
      {property.ratings[0]?.createdAt
 
        ? property.ratings[0].createdAt
 
        : ""} 
    </span>
  ) : (
    ""
  )}
    
            </div>
        </div>
        <div className="content">
          <div className="star-app">
          <div className="stars">
          {property.ratings && property.ratings.length > 0 ? (
  <span>
    {property.ratings.map((rating, index) => {
      const stars = [];
      if (rating.rating) {
        const maxStars = 5; 
        const roundedRating = Math.round(rating.rating); 
        for (let i = 0; i < maxStars; i++) {
          if (i < roundedRating) {
            stars.push(<span key={i}>★</span>);
          } else {
            stars.push(<span key={i}>☆</span>); 
          }
        }
      }

      return (
        <span key={index}>
          {stars} 
          {index < property.ratings.length - 1 && ", "} 
        </span>
      );
    })}
  </span>
) : (
  ""
)}
           </div>
           <div>
           <h2 className="footer">
           {property.ratings?.length > 0 ? (
  <span>
    {property.ratings[0]?.user_id?.username
      ? property.ratings[0].user_id.username
      : "No reviews yet"}
  </span>
) : (
  <span>No ratings available</span>
)}
            </h2>
           </div>
          </div>
          <div className="text">
          {property.ratings?.length > 0 ? (
    <span>
      {property.ratings[0]?.description
        ? property.ratings[0].description
 
        : ""} 
    </span>
  ) : (
    ""
  )}
           </div>
        </div>
       
      </div> */}

      {/* <div className="review-card">
        <div className="header">
          <div className="title">
          {property.ratings?.length > 0 ? (
    <span>$
      {property.ratings[0]?.comment 
        ? property.ratings[0].comment
        : ""}
    </span>
  ) : (
   ""
  )}  
            </div>
          <div className="date">
          {property.ratings?.length > 0 ? (
    <span>
      {property.ratings[0]?.createdAt
 
        ? property.ratings[0].createdAt
 
        : ""} 
    </span>
  ) : (
    ""
  )}
            </div>
        </div>
        <div className="content">
        <div className="star-app">
          <div className="stars">
          {property.ratings && property.ratings.length > 0 ? (
  <span>
    {property.ratings.map((rating, index) => {
      const stars = [];
      if (rating.rating) {
     
        const maxStars = 5; 
        const roundedRating = Math.round(rating.rating);
        for (let i = 0; i < maxStars; i++) {
          if (i < roundedRating) {
            stars.push(<span key={i}>★</span>);
          } else {
            stars.push(<span key={i}>☆</span>); 
          }
        }
      }

      return (
        <span key={index}>
          {stars}
          {index < property.ratings.length - 1 && ", "} 
        </span>
      );
    })}
  </span>
) : (
  ""
)}
           </div>
           <div>
           <h2 className="footer">
           {property.ratings?.length > 0 ? (
  <span>
    {property.ratings[0]?.user_id?.username
      ? property.ratings[0].user_id.username
      : "No reviews yet"}
  </span>
) : (
  <span>No ratings available</span>
)}
            </h2>
           </div>
          </div>
          
          <div className="text">
          {property.ratings?.length > 0 ? (
    <span>
      {property.ratings[0]?.description
        ? property.ratings[0].description
 
        : ""} 
    </span>
  ) : (
    ""
  )}
            </div>
        </div>
        
      </div> */}
      <Link to="/review" style={{ textDecoration: "none" }}>

      <div className="links">
        <a className="link" href="#">Write a review</a>
        <a className="link" href="#" style={{ float: 'right' }}>View all</a>
      </div>
      </Link>
      <br></br>
      <div>

      <h2>Related Properties</h2>
      </div>
      <div className="property-card">
        <div className="header">
          <div className="image">
          <img
                      src={
                        property.uploads?.length > 0? 
                        property.uploads[0].media :
                        "https://via.placeholder.com/400x200"
                      }
                      alt={property.property_title}
                    />
       
          </div>
          <div className="details">
            <div className="title">

              {property.property_title}</div>
            <div className="tags">
              <span className="tag">

               {property.status
                                        }</span>
            </div>
            <div className="info-app">
              <div>
              <LiaMapMarkerAltSolid className="i-cloud"/>
              {property.address?.length > 0 ? (
    <span>
      {property.address[0]?.address 
        ? property.address[0].address 
        : ""} 
    </span>
  ) : (
    ""
  )}
              </div>
              <div>
              <FaRulerCombined className="i-cloud"/>
              <span>
    {property.details?.length > 0 ? (
      property.details[0]?.area_size?.$numberDecimal 
        ? property.details[0].area_size.$numberDecimal || "sq ft"
        : "N/A"
    ) : (
      "" 
    )} sq ft </span>
              </div>
              <div className="link-price">
                <div>
              <a className="link" href="#">
              {property.property_type}</a>
              </div>
              <div className="price">
              ${property.after_price}
     {/* {property.sale_rent_price}
      {property.second_price} */}
                K</div>
              </div>
            </div>
            
          </div>
        </div>
      </div>

      <div className="property-card">
        <div className="header">
          <div className="image">
          <img
                      src={
                        property.uploads?.length > 0? 
                        property.uploads[0].media :
                        "https://via.placeholder.com/400x200"
                      }
                      alt={property.property_title}
                    />
       
          </div>
          <div className="details">
            <div className="title">

              {property.property_title}</div>
            <div className="tags">
              <span className="tag">

               {property.status
                                        }</span>
            </div>
            <div className="info-app">
              <div>
              <LiaMapMarkerAltSolid className="i-cloud"/>
              {property.address?.length > 0 ? (
    <span>
      {property.address[0]?.address 
        ? property.address[0].address 
        : ""} 
    </span>
  ) : (
    ""
  )}
              </div>
              <div>
              <FaRulerCombined className="i-cloud"/>
              <span>
    {property.details?.length > 0 ? (
      property.details[0]?.area_size?.$numberDecimal 
        ? property.details[0].area_size.$numberDecimal || "sq ft"
        : "N/A"
    ) : (
      "" 
    )} sq ft </span>
              </div>
              <div className="link-price">
                <div>
              <a className="link" href="#">
              {property.property_type}</a>
              </div>
              <div className="price">
              ${property.after_price}
     {/* {property.sale_rent_price}
      {property.second_price} */}
                K</div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
  
    <div className="contact-buttons">
    <div className="contact-button email">
      <a
        href={email ? `mailto:${email}` : '#'}
        aria-label={email ? `Email ${email}` : 'No email available'}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textDecoration: 'none',
        }}
      >
        <FaRegEnvelope style={{ fontSize: '24px', cursor: 'pointer' }} /> {/* Icon only */}
      </a>
    </div>

      <div className="contact-button call">
      <a 
  href={whatsappNumber ? `https://wa.me/${whatsappNumber}` : "#"} 
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
  }}
  target="_blank" 
  rel="noopener noreferrer"
>
  {/* Display only the icon */}
  <LuPhone />
</a>
      {/* <a 
            href={whatsappNumber ? `https://wa.me/${whatsappNumber}` : "#"} 
          
            target="_blank" 
            rel="noopener noreferrer"
        >
            {whatsappNumber && <span>{whatsappNumber}</span>}
            <LuPhone />
        </a> */}
        </div>
    </div>
  </div>
  </>
  )
}

export default DetailProperty