import { red } from "@mui/material/colors";
import React, { useState, useEffect } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const ProfilePage = () => {
  const { id } = useParams(); // Get the agent ID from the URL
  const [agent, setAgent] = useState(null);

  const fetchAgent = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        'ngrok-skip-browser-warning': 'any',
        authorization: "Bearer " + localStorage.getItem("bearer-token"),
      },
    };

    const response = await fetch(
      `${process.env.REACT_APP_backend_server_url}/users/single/${id}`, // Fetch agent by ID
      requestOptions
    );

    if (response.ok) {
      const result = await response.json();
    
      setAgent(result); 
      console.log("Agent fetched successfully", result);
    } else {
      const result = await response.json();
      console.error("Error fetching Agent details", result);
    }
  };

  useEffect(() => {
    fetchAgent();
  }, [id]);

  if (!agent) {
    return <p>Loading...</p>; 
  }
  const email = agent.user_id?.email;
  const whatsappNumber = agent.userprofiles?.length > 0 ? agent.userprofiles[0].whatsapp_number : "";

  
  return (
    <div className="container-page">
    <div className="profile-header">
      <img
        alt="Profile"
        height="100"
        className="Image"
        src={agent.addimages[0]?.profile_image || "https://via.placeholder.com/80"}
        width="80"
      />
      <div className="info-page">
        <h1>{agent.userprofiles[0]?.first_name || "Agent Name"}</h1>
        <h2>{agent.userprofiles[0]?.company_name || "Company Name"}</h2>
        <div className="page-app">
          <p>
            <FaMapMarkerAlt /> {agent.userprofiles[0]?.address || "No Address"}
          </p>
        </div>
      </div>
    </div>

    <div className="license">
      <p className="l-page">License: </p>
      <p>{agent.userprofiles[0]?.license || "N/A"}</p>
    </div>

    <div className="about">
      <h3>About</h3>
      <p>{agent.userprofiles[0]?.about_me || "No About Info Available"}</p>
    </div>

    <div className="review">
      <h3>

         Give a review</h3>
      <p>
      {agent.ratings?.length > 0 ? (
    <span>
      {agent.ratings[0]?.description
        ? agent.ratings[0].description
 
        : ""} 
    </span>
  ) : (
    ""
  )}
      </p>
      <div className="stars">
      {agent.ratings && agent.ratings.length > 0 ? (
  <span>
    {agent.ratings.map((rating, index) => {
      const stars = [];
      if (rating.rating) {
        // Create stars with a for loop
        const maxStars = 5; // Total number of stars
        const roundedRating = Math.round(rating.rating); // Ensure the rating is an integer
        for (let i = 0; i < maxStars; i++) {
          if (i < roundedRating) {
            stars.push(<span key={i}>★</span>); // Filled star
          } else {
            stars.push(<span key={i}>☆</span>); // Empty star
          }
        }
      }

      return (
        <span key={index}>
          {stars} {/* Render stars */}
          {index < agent.ratings.length - 1 && ", "} {/* Add a comma between ratings */}
        </span>
      );
    })}
  </span>
) : (
  ""
)}
      </div>
      <Link to="/review" style={{ textDecoration: "none" }}>

      <span className="write-review">Write a review</span>
      </Link>
    </div>

    <div className="contact">
      <div className="button email">
      <a
        href={email ? `mailto:${email}` : '#'}
        aria-label={email ? `Email ${email}` : 'No email available'}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textDecoration: 'none',
        }}
      >
        <FaEnvelope  className="email-app" style={{cursor: 'pointer', color: 'red' }} /> {/* Icon only */}
      </a>EMAIL
        {/* <FaEnvelope className="email-app" /> EMAIL */}
      </div>
      <div className="button call">
      <a 
  href={whatsappNumber ? `https://wa.me/${whatsappNumber}` : "#"} 
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
  }}
  target="_blank" 
  rel="noopener noreferrer"
>
  {/* Display only the icon */}
  <FaPhoneAlt className="email"  style={{ cursor: 'pointer', color: 'red' }} />
</a>CALL
        {/* <FaPhoneAlt className="email-app" /> CALL */}
      </div>
      <div className="button whatsapp">
      <a 
  href={whatsappNumber ? `https://wa.me/${whatsappNumber}` : "#"} 
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
  }}
  target="_blank" 
  rel="noopener noreferrer"
>
  {/* Display only the icon */}
  <FaWhatsapp className="email"  style={{ cursor: 'pointer', color: 'red' }}  />
</a>WhatsApp

        {/* <FaWhatsapp className="email-app" /> WhatsApp */}
      </div>
    </div>
  </div>

  );
};

export default ProfilePage;
