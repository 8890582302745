import React, { useState } from 'react';
import { Button } from '@mui/material';


const Virtual = () => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };
     //button
  const handleClick = () => {
    console.log('Button clicked!');
  };

  return (
    <form>
      <div className="add-property">
        <div className="form-wrapper" style={{ boxShadow: '0px 1px 3px -1px', marginTop: '12px', borderRadius: '9px' }}>
          <span className="form-span">360 Virtual Tour</span>
          <div  className="form-control">
          <input
          className='form-enter'
      type="text"
      value={inputValue}
      onChange={handleInputChange}
      placeholder="Enter  virtual tour iframe/embedded code"
    />
    </div>
        </div>
        <div className="form-wrapper" style={{boxShadow:'0px 1px 3px -1px',marginTop:'12px', borderRadius:'9px'}}>
      <div style={{display: 'flex',
    justifyContent: 'space-between'}}>
<div  className="form-containe">
      <Button
      className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleClick}
      >
        Cancel
      </Button>
    </div>
    <div className='form-contain'>
      <Button
       className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleClick}
      >
       Next
      </Button>
    </div>
</div>

      </div>
      </div>
    </form>
  );
};



export default Virtual;