import React, { useState } from 'react';
import { Button } from '@mui/material';


const Agreement = () => {
    const [gdprAgreement, setGdprAgreement] = useState(false);

    const handleGdprAgreementChange = (event) => {
      setGdprAgreement(event.target.checked);
    };
         //button
  const handleClick = () => {
    console.log('Button clicked!');
  };
  return (
    <form>
      <div className="add-property">
        <div className="form-wrapper" style={{ boxShadow: '0px 1px 3px -1px', marginTop: '12px', borderRadius: '9px' }}>
          <span className="form-span">GDPR Agreement</span>
          <div className="form-controller">
      <input
        type="checkbox"
        checked={gdprAgreement}
        onChange={handleGdprAgreementChange}
        id="gdpr-agreement"
      />
      <label htmlFor="gdpr-agreement">
        I Consent to having this website to store my submitted information, reed more information<a href="#" target="_blank">GDPR  Agreement</a>
      </label>
    </div>
          </div>
          <div className="form-wrapper" style={{boxShadow:'0px 1px 3px -1px',marginTop:'12px', borderRadius:'9px'}}>
      <div style={{display: 'flex',
    justifyContent: 'space-between'}}>
<div  className="form-containe">
      <Button
      className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleClick}
      >
        Back
      </Button>
    </div>
    <div className='form-contain'>
      <Button
       className='form-button'
        variant="contained"
        color="primary"
        size="lg"
        disabled={false}
        onClick={handleClick}
      >
       Cancel
      </Button>
    </div>
</div>

      </div>
          </div>
          </form>
  )
}

export default Agreement